enum StatusTypes {
  PRECONFECCIONADA = 'preconfeccionada',
  POR_VENCER = 'por vencer',
  VENCIDA = 'vencida',
  AGREGADA_AL_CARRITO = 'agregada al carrito',
  SIN_ESTADO = 'sin estado',
  PAGADA = 'pagada',
  SIN_VENCIMIENTO = 's/vto',
  VENCE_HOY = 'vence hoy',
  EN_PROCESO = 'en proceso',
  CONFECCIONADA = 'confeccionada',
}

export default StatusTypes;
