// eslint-disable-next-line import/no-unresolved
import { getToken } from '@react-ib-mf/style-guide-ui';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { IAliasFavoritesResponse } from '../interfaces/IAliasFavorites';
import { IPreconfectionCart, IPreconfectionStatus } from '../interfaces/IButtonPayCart';
import { ICartItemsQuantity } from '../interfaces/ICartItemsQuantity';
import { ICartsResponse } from '../interfaces/ICarts';
import { IMaxItemsResponse } from '../interfaces/IMaxItems';
import { IFormModalityResponse } from '../interfaces/IModalityForm';
import { INextExpirations } from '../interfaces/INextExpirationsBills';
import { IOperatibility } from '../interfaces/IOperatibility';
import { TableCheckout } from '../interfaces/ITableCheckout';
import { PermissionsResponse } from '../interfaces/Permissions';

const newAxios = axios.create({
  baseURL: process.env.PAGOS_BASE_URL,
  headers: { Authorization: `Bearer ${getToken()}`, user_key: `${process.env.API_USER_KEY}` },
});

// const newAxiosNestPagos = axios.create({
//   baseURL: process.env.PAGOS_NEST_BASE_URL,
//   headers: { Authorization: `Bearer ${getToken()}`, user_key: `${process.env.API_USER_KEY}` },
// });

export const getCompanies = () => newAxios.get('companies');
export const postAvailableBills = fetchOptions => newAxios.post('bills', fetchOptions);
export const getModalities = id => newAxios.get(`/companies/${id}/modalities`);
export const getModalityForm = (company: string, modality: string) =>
  newAxios.get<IFormModalityResponse>(`/companies/${company}/modalities/${modality}`);
export const createServiceAlias = payload => newAxios.post('/alias', payload);
export const getExistingAlias = payload => newAxios.post('/alias/existing', payload);
export const getMaxItems = () => newAxios.get<IMaxItemsResponse>(`/carts/maxitems`);
export const getCartsItems = () => newAxios.get<ICartsResponse>(`/carts/items`);
export const getAliasFavorites = (page: number, pageSize: number) =>
  newAxios.get<IAliasFavoritesResponse>(`/alias/favorites`, { params: { page, pageSize } });
export const deleteAlias = aliasId => newAxios.delete(`/alias/${aliasId}`);
export const getVoucher = postPaymentReceiptBody => newAxios.post('/voucher/payment', postPaymentReceiptBody);
export const getPermissions = () => newAxios.get<PermissionsResponse>(`authorizer/permissions`);
export const createCart = () => newAxios.post('/carts');
export const createCartItem = (hash, payload) => newAxios.post(`/carts/items/${hash}`, payload);
export const createCartItemList = (items: AxiosRequestConfig[]) => Promise.allSettled(items);
export const getCartCompanyItems = () => newAxios.get<ICartsResponse>(`/carts/items?group_by=empresa`);
export const deleteCarts = () => newAxios.delete('/carts');
export const getCartItemsQuantity = () => newAxios.get<ICartItemsQuantity>(`carts/items/quantity`);
export const deleteCartBill = hash => newAxios.delete(`carts/items/${hash}`);
export const deleteCartCompany = hashList => newAxios.post(`carts/items`, hashList);
export const preconfection = () => newAxios.post<IPreconfectionCart>(`/payments/preconfection`);
export const preconfectionStatus = preconfectionId =>
  newAxios.get<IPreconfectionStatus>(`/payments/preconfection/status/${preconfectionId}`);
export const deleteBillsPreconfection = () => newAxios.delete(`/carts/invoice/items`);
export const paymentPrepackaging = postBillsBody => newAxios.post('/payments/validateAndPrepare', postBillsBody);
export const downloadReceipt = payment =>
  newAxios.get(`/voucher/pdf/${payment}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  });
export const getOperatibility = () => newAxios.get<IOperatibility>(`operability`);
export const downloadReceiptZip = postZipPdfBody =>
  newAxios.post('/voucher/zip/pdf/', postZipPdfBody, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/zip',
    },
  });
export const updateServiceAlias = (alias: string, aliasId: number) => newAxios.put(`/alias/${aliasId}`, { alias });
export const getCompaniesReceipt = () => newAxios.get('companies/voucher');
export const getNextExpirations = (sortProperty: string, sortDirection: string, page: number, size: number) =>
  newAxios.get<INextExpirations>('nextExpirations', {
    params: { page, size, 'sort-field': sortProperty, 'sort-direction': sortDirection },
  });
export const getDataTableCheckout = ({ params = null }): Promise<AxiosResponse<TableCheckout>> => {
  return newAxios.get(`accounts`, { params: { ...params } });
};

export const deleteItemsFromCart = ({ hashes }) => newAxios.post('/carts/items', { hashes });
export const getConfection = ({ debitAccountId }) => newAxios.post('/confection', { debitAccountId });
export const getConfectionStatus = ({ uuid }) => newAxios.get(`payments/confection/status/${uuid}`);
