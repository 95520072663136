/* eslint-disable react/forbid-prop-types */
import { FormControl, FormHelperText, Icon, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { X } from 'react-feather';
import Select, { components } from 'react-select';

import AppContext from '../../context/Provider';
import { IData } from '../../interfaces/IFilter';
import { labelText } from '../../styled/generalStyles';
import { DataFilterInformation } from '../../text/GeneralText';
import DropdownActions from './DropdownActions';
import { Container, Styled } from './styled';

// TODO - do this with typescript
FilterComponent.propTypes = {
  handleValue: PropTypes.func,
  filterInfo: PropTypes.object,
  dataFilter: PropTypes.object,
  initValue: PropTypes.any,
};

function FilterComponent(props) {
  const { handleValue, filterInfo, initValue, dataFilter: data }: IData = props;

  const [dataFilter, setDataFilter] = useState([{ value: '', label: '' }]);
  const [value, setValue] = useState(initValue);
  const [previousValue, setPreviousValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const { persistedForm, setIsButtonAvailable } = useContext(AppContext);

  const [isClearable] = useState(true);

  const clearMenuOnClose = () => {
    setValue(null);
    handleValue(null);
    setIsButtonAvailable(false);
  };

  const onKeyDown = (e: { keyCode: number; preventDefault: () => void }) => {
    if (e.keyCode === 32) {
      e.preventDefault();
      setInputValue(`${inputValue} `);
    }
  };

  useEffect(() => {
    if (data) {
      setDataFilter(
        data.data.companies.map(item => {
          return { value: item.code, label: item.name };
        }),
      );
    }
  }, [data]);

  useEffect(() => {
    DropdownActions.setCallFunction(clearMenuOnClose);
    sessionStorage.removeItem('persisted-form');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (persistedForm?.companyName?.length > 0) {
      setPreviousValue(persistedForm?.companyName);
    }
  }, [persistedForm?.companyName]);

  // eslint-disable-next-line react/no-unstable-nested-components
  function ClearIndicatorIcon() {
    return <Icon as={X} w={6} h={6} />;
  }

  function clearInd(e) {
    setPreviousValue(null);
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.ClearIndicator {...e}>
        <ClearIndicatorIcon />
      </components.ClearIndicator>
    );
  }

  return (
    <Container>
      {data !== undefined && (
        <FormControl>
          {value && value.label?.length > 0 && <FormHelperText style={labelText}>{DataFilterInformation.placeholder}</FormHelperText>}
          <Select
            isClearable={isClearable}
            styles={Styled}
            inputValue={inputValue}
            onInputChange={v => setInputValue(v)}
            onChange={val => {
              setValue(val);
              handleValue(val);
            }}
            onKeyDown={onKeyDown}
            placeholder={
              <div>
                <Text textStyle='placeholder'>{previousValue || filterInfo.filterValue}</Text>
              </div>
            }
            value={value}
            options={dataFilter}
            noOptionsMessage={() => {
              return filterInfo.noInformation;
            }}
            components={{ DropdownIndicator: () => null, ClearIndicator: e => clearInd(e) }}
          />
        </FormControl>
      )}
    </Container>
  );
}

export default FilterComponent;
