import { Box, Center, Grid, GridItem } from '@chakra-ui/react';

import CommonPage from '../../components/CommonPage';
import CreatePaymentButton from '../../components/CreatePaymentButton';
import FavoriteHome from '../../components/FavoriteHome';
import GoCartButton from '../../components/GoCartButton';
import { FavoritePaymentsTexts } from '../../text/GeneralText';

function FavoritePayments() {
  const renderButtons = () => {
    return (
      <>
        <Box>
          <GoCartButton />
        </Box>
        <Center>
          <Box>
            <CreatePaymentButton />
          </Box>
        </Center>
      </>
    );
  };

  return (
    <Grid templateColumns='repeat(12, 1fr)' gap={16}>
      <GridItem colSpan={12}>
        <CommonPage headline={FavoritePaymentsTexts.header} text={FavoritePaymentsTexts.subheader} showBreadcrumb buttons={renderButtons()}>
          <FavoriteHome />
        </CommonPage>
      </GridItem>
    </Grid>
  );
}

export default FavoritePayments;
