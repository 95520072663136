import { Button, Tooltip } from '@chakra-ui/react';
import { pushAnalyticsEvent } from '@wow/utils';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import { TooltipTexts } from '../../text/GeneralText';
import { renderContentTextButton } from '../../utils/converters';

function CreatePaymentButton() {
  const { deleteDataServicePayment, permissions } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const handleGoNewPayment = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones seccion pagos',
      item_id: 'Buscar servicio',
    });
    deleteDataServicePayment();
    navigate(`/${routesNames.CreatePayment}`);
  };

  return (
    <Tooltip
      isDisabled={permissions?.generacionPreconfeccionPropia && permissions?.generacionConfeccion}
      hasArrow
      placement='left'
      label={TooltipTexts.withoutPermissions}
      shouldWrapChildren
    >
      <Button
        loadingText='Button'
        size='md'
        spinnerPlacement='start'
        isDisabled={!permissions?.generacionPreconfeccionPropia || !permissions?.generacionConfeccion}
        variant='primary'
        onClick={() => handleGoNewPayment()}
      >
        {renderContentTextButton(location.pathname)}
      </Button>
    </Tooltip>
  );
}

export default CreatePaymentButton;
