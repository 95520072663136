/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import { Button, Modal, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { useState } from 'react';

import { ModalProps } from '../../interfaces/IConfirmationModal';
import { AliasDeleteModalText } from '../../text/GeneralText';
import { ButtonContainer, iconClose } from './styled';

function ConfirmationModal({
  isOpen,
  onClose,
  modalSize = 'xl',
  ModalContentCss,
  cancelText = '',
  acceptButton = AliasDeleteModalText.deleteAliasButton,
  handleClickButtonClose = () => null,
  children,
  fn,
}: ModalProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleAction = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await fn();
    } finally {
      onClose();
      setIsLoading(false);
    }
  };
  if (!isOpen) return null;
  return (
    <Modal size={modalSize} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} data-testid='modal' isCentered>
      <ModalOverlay />
      <ModalContent css={ModalContentCss}>
        <ModalCloseButton style={iconClose} onClick={handleClickButtonClose} data-testid='button-close' />

        {children}
        <ButtonContainer>
          {cancelText && (
            <Button
              paddingX='24px'
              marginRight='12px'
              variant='outline'
              colorScheme='blue'
              onClick={onClose}
              size='md'
              data-testid='button-cancelText'
            >
              {cancelText}
            </Button>
          )}
          <Button
            size='md'
            isDisabled={isLoading}
            className='margin15'
            onClick={handleAction}
            variant='primary'
            paddingX='24px'
            data-testid='btnDeleteModal'
          >
            {acceptButton}
          </Button>
        </ButtonContainer>
      </ModalContent>
    </Modal>
  );
}
export default ConfirmationModal;
