import { BreadcrumbComponent } from '@wow/breadcrumb';
import { Outlet } from 'react-router-dom';

import Footer from '../components/Footer';
import routesNames from '../constants/routesNames';
import AppContextProvider from '../context/AppContextProvider';
import useBreadcrumb from '../hooks/useBreadcrumb';
import PagosPanel from './pagosStyled';

function Pagos() {
  const [breadcrumbItems] = useBreadcrumb();

  function renderFooter() {
    switch (window.location.pathname) {
      case `/${routesNames.ReceiptsPayments}`:
      case `/${routesNames.Home}`:
        return null;
      case `/${routesNames.FavoritePayments}`:
        return null;
      case `/${routesNames.SummaryPayments}`:
        return null;
      // eslint-disable-next-line no-fallthrough
      default:
        return <Footer />;
    }
  }

  return (
    <AppContextProvider>
      <PagosPanel>
        <BreadcrumbComponent items={breadcrumbItems} margin='14px 0' marginBottom={0} />
        <Outlet />
        {renderFooter()}
      </PagosPanel>
    </AppContextProvider>
  );
}

export default Pagos;
