/* eslint-disable import/no-unresolved */
import { Grid, GridItem, Text } from '@chakra-ui/react';
import { AlertComponent, AlertDescription, AlertTitle } from '@wow/alert';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import { OpenMode, pushAnalyticsEvent } from '@wow/utils';
import { useWizard, WizardComponent } from '@wow/wizard';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CardHome from '../../components/CardHome';
import CommonPage from '../../components/CommonPage';
import CreatePaymentButton from '../../components/CreatePaymentButton';
import ExpirationCalendar from '../../components/ExpirationCalendar';
import GoCartButton from '../../components/GoCartButton';
import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import {
  AlertBannerCheckoutHome,
  AlertBannerInformation,
  DataExpirationHome,
  DataHeaderHome,
  IndexWizardSteps,
  PreparePaymentText,
} from '../../text/GeneralText';
import { Container, ContainerExpirationHome } from './styled';
import WizardSteps from './WizardSteps';

function Home() {
  const {
    cartFull,
    cartItemsCount,
    operative,
    setCartFull,
    startOperative,
    endOperative,
    maxCartItem,
    validateOperatibility,
    getCartData,
    isLoadingScreen,
    paymentCheckoutProcess,
    setPaymentCheckoutProcess,
  } = useContext(AppContext);
  const [wizard, initWizard] = useWizard(WizardSteps);
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  const [isFullCart, setIsFullCart] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('payment-onboarding') == null) {
      initWizard('payment-onboarding');
    }
    getCartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFinishWizard = () => {
    localStorage.setItem('payment-onboarding', 'true');
    setCurrentStep(0);
  };

  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Pagos',
    });

    validateOperatibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // muestra carrito
  useEffect(() => {
    if (cartItemsCount && maxCartItem) {
      setCartFull(cartItemsCount >= maxCartItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItemsCount, maxCartItem, isFullCart]);

  const goToCart = () => {
    validateOperatibility().then(operativeTerminal => {
      if (!operativeTerminal) {
        return navigate(`/${routesNames.Home}`);
      }
      return navigate(`/${routesNames.PaymentCart}`);
    });
  };
  const handleClickAuthorizations = () => {
    window.open(
      OpenMode({
        module: 'urlComplete',
        urlComplete: `${process.env.URL_DOMAIN_SIB}${process.env.SIB_TRANSFERENCIAS_AUTORIZACIONES}`,
      }),
    );
  };

  if (currentStep === IndexWizardSteps.stepThree) window.scrollTo(0, document.body.scrollHeight);

  return (
    <Container>
      {isLoadingScreen && <LoadingScreen title={PreparePaymentText.title} description={PreparePaymentText.subtitle} />}
      <Grid templateColumns='repeat(12, 1fr)' gap={16}>
        <GridItem colSpan={12}>
          <Container>
            <div className='header'>
              <Text textStyle='h1' id='titlePay'>
                {DataHeaderHome.title}
              </Text>
              <div className='buttonHeader'>
                <GoCartButton />
                <div id='searchBills' className='createPay'>
                  <CreatePaymentButton />
                </div>
              </div>
            </div>
            {!operative ? (
              <AlertComponent id='alert-homeOperative' marginTop={4} variant='destructive' width='100%'>
                <AlertTitle>{AlertBannerInformation.error.operatibilityTitle}</AlertTitle>
                <AlertDescription>{AlertBannerInformation.error.operatibilityDescription(startOperative, endOperative)}</AlertDescription>
              </AlertComponent>
            ) : null}
            {paymentCheckoutProcess && (
              <AlertComponent
                buttonLabel={AlertBannerCheckoutHome.buttonLabel}
                onClick={handleClickAuthorizations}
                id='alert-checkout'
                data-testid='alert-checkout'
                variant='warning'
                width='100%'
                marginBottom='24px'
                marginTop='10px'
                onClose={() => setPaymentCheckoutProcess(prev => !prev)}
              >
                <AlertTitle>{AlertBannerCheckoutHome.title}</AlertTitle>
                <AlertDescription dangerouslySetInnerHTML={{ __html: AlertBannerCheckoutHome.description }} />
              </AlertComponent>
            )}
          </Container>
          <CardHome />
          <ContainerExpirationHome>
            <div className='marginTop20'>
              <CommonPage headline={DataExpirationHome.title} text={DataExpirationHome.subtitle} />
            </div>
            {(cartFull || isFullCart) && !currentStep && operative ? (
              <AlertComponent
                buttonLabel={AlertBannerInformation.error.goToCart}
                onClick={() => goToCart()}
                id='alert-expirationHome'
                variant='destructive'
                width='100%'
                marginBottom='24px'
                marginTop='10px'
              >
                <AlertTitle>{AlertBannerInformation.error.titleCartFull}</AlertTitle>
                <AlertDescription>{AlertBannerInformation.error.descriptionCartFull(maxCartItem)}</AlertDescription>
              </AlertComponent>
            ) : null}
          </ContainerExpirationHome>
          <ExpirationCalendar
            isFullCart={setIsFullCart}
            isWizardActive={currentStep !== IndexWizardSteps.initialStep}
            wizzardCurrentStept={currentStep}
          />
        </GridItem>
        <GridItem />
      </Grid>
      {wizard.initialized && (
        <WizardComponent
          id='payment-onboarding'
          steps={WizardSteps}
          width={300}
          height={220}
          onFinish={() => handleFinishWizard()}
          onPrevious={() => {
            setCurrentStep(currentStep - 1);
          }}
          onNext={() => {
            setCurrentStep(currentStep + 1);
          }}
        />
      )}
    </Container>
  );
}

export default Home;
