/* eslint-disable import/no-unresolved */
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { pushAnalyticsEvent } from '@wow/utils';
import { useContext, useEffect, useState } from 'react';

import AppContext from '../../context/Provider';
import { AliasModalProps } from '../../interfaces/IAliasModalProps';
import { createServiceAlias, updateServiceAlias } from '../../services/api';
import { colors } from '../../styled/generalStyles';
import { AliasModalText } from '../../text/GeneralText';
import { dateValueToStringDDMMYYYY } from '../../utils/converters';
import { AliasModalConf, AliasModalConfType } from './modalConf';
import { ContainerFooter, ContainerSubtitle, iconClose, iconStar } from './styled';

function AliasModal({ isOpen, onClose, onSaveSuccess, onSaveError, paymentAlias, variant = 'create', setAliasIdLoading }: AliasModalProps) {
  const { persistedForm, modalitiesForm } = useContext(AppContext);
  const [serviceAlias, setServiceAlias] = useState(paymentAlias?.alias || '');
  const [showError, setShowError] = useState(false);

  const conf: AliasModalConfType = AliasModalConf[variant];

  useEffect(() => {
    if (variant === 'create' && isOpen) {
      pushAnalyticsEvent({
        event: 'modal_impression',
        modal_name: 'Guardar servicio como favorito',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handlerCreateAlias = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Guardar servicio como favorito',
      item_id: 'Guardar',
    });
    if (!serviceAlias || serviceAlias.trim() === '') {
      setShowError(true);
    } else {
      const aliasToCreate = {
        alias: serviceAlias.trim(),
        companyCode: persistedForm.companyId,
        formNodeAlias: modalitiesForm.fields,
        idGireModality: persistedForm.modalityId,
      };
      createServiceAlias(aliasToCreate)
        .then(() => {
          onSaveSuccess(aliasToCreate.alias);
        })
        .catch(() => {
          onSaveError(null, aliasToCreate);
        })
        .finally(() => {
          setServiceAlias('');
        });
      setServiceAlias(paymentAlias?.alias || '');
      onClose();
    }
  };

  const handlerUpdateAlias = () => {
    if (!serviceAlias || serviceAlias.trim() === '') {
      setShowError(true);
    } else {
      setAliasIdLoading(paymentAlias.aliasId);
      onClose();
      updateServiceAlias(serviceAlias, paymentAlias.aliasId)
        .then(() => {
          onSaveSuccess(serviceAlias);
        })
        .catch(() => {
          onSaveError({
            tempAlias: serviceAlias,
            currentAlias: paymentAlias?.alias,
            aliasId: paymentAlias.aliasId,
            companyName: paymentAlias.companyName,
          });
        })
        .finally(() => {
          setAliasIdLoading(undefined);
        });
    }
  };

  const handleOnClose = () => {
    setServiceAlias(paymentAlias?.alias || '');
    setShowError(false);
    onClose();
  };

  const handleOnChange = e => {
    /* This expression allows you to enter uppercase and lowercase letters with or without accents,
     numbers, hyphen, underscore, and period. */
    if (e.target.value.match('^[a-z A-ZáéíóúÁÉÍÓÚ0-9_.-]*$') !== null) {
      setServiceAlias(e.target.value.trimStart());
    }
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => handleOnClose()} isCentered size='sm'>
      <ModalOverlay />
      <ModalContent borderRadius='12px'>
        <ModalHeader>
          <ModalCloseButton sx={iconClose} color={colors.blue} className='iconClose' />
        </ModalHeader>
        <ModalBody>
          <ContainerSubtitle>
            <Icon as={conf.icon} style={iconStar} />
            <Text textStyle='h3'>{conf.title}</Text>
            {variant === 'create' && (
              <Text textStyle='bodyMd' className='textH6'>
                {AliasModalText.subtitle}
              </Text>
            )}
            {variant === 'edit' && paymentAlias && (
              <div>
                <Text textStyle='h6' className='textH6'>
                  {paymentAlias.companyName}
                </Text>
                <Text textStyle='bodyMd'>{`${AliasModalText.creationDateField} ${
                  paymentAlias.createDate ? dateValueToStringDDMMYYYY(new Date(paymentAlias.createDate)) : ''
                }`}</Text>
                {Object.keys(paymentAlias?.form).map(key => (
                  <Text textStyle='bodyMd' className='textbodyMd' key={paymentAlias.aliasId}>
                    {paymentAlias.references[key]}:{paymentAlias.form[key]}
                  </Text>
                ))}
              </div>
            )}
          </ContainerSubtitle>
          <FormControl isInvalid={showError}>
            <FormLabel>{variant === 'create' ? conf.label : conf.inputLabel}</FormLabel>
            <Input
              aria-label='alias-name'
              maxLength={50}
              value={serviceAlias}
              placeholder={variant === 'create' && !showError ? conf.inputLabel : ''}
              onChange={e => {
                handleOnChange(e);
              }}
            />
            <FormErrorMessage>{AliasModalText.inputError}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ContainerFooter>
            <Button
              className='marginButton'
              aria-label='cancel-btn'
              variant='primary-text'
              onClick={handleOnClose}
              size='md'
              paddingX='24px'
              data-testid='cancel-btn'
            >
              {AliasModalText.cancelButton}
            </Button>
            <Button
              aria-label='cancel-btn'
              variant='primary'
              onClick={() => (variant === 'create' ? handlerCreateAlias() : handlerUpdateAlias())}
              size='md'
              paddingX='24px'
              data-testid='save-Btn'
            >
              {AliasModalText.saveAliasButton}
            </Button>
          </ContainerFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AliasModal;
