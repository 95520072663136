/* eslint-disable import/no-unresolved */
import { Icon, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { pushAnalyticsEvent } from '@wow/utils';
import { Edit2, Trash } from 'react-feather';

import { IFavoriteItemProps } from '../../../interfaces/IFavoriteItemProps';
import { cardFavoritesText } from '../../../text/GeneralText';
import { formatDate } from '../../../utils/FormatChange';
import TextEllipsisWithTooltip from '../../TextEllipsisWithTooltip';
import FavoriteModal from '../FavoriteModal';
import { CardContainer } from './styled';

function FavoriteItem({ item, onEdit, onDelete }: IFavoriteItemProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');

  const handleDeleteFavorites = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones seccion pagos',
      item_id: 'Pagos favoritos-Eliminar',
    });
    onOpen();
  };

  const handleOnEdit = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones seccion pagos',
      item_id: 'Pagos favoritos-Modificar',
    });
    onEdit();
  };

  return (
    <CardContainer>
      <div key={item.aliasId} className='card' data-testid='card'>
        <div className={isLargerThan1200 ? 'textCardLargerThan1200' : 'textCardSmallerThan1200'}>
          <TextEllipsisWithTooltip
            text={item.companyName}
            maxWidth={isLargerThan1200 ? '350px' : '200px'}
            placement='top'
            textStyle='titleMdBold'
            padding='0px 0px 10px 0px'
          />
          <TextEllipsisWithTooltip
            text={item.alias}
            maxWidth={isLargerThan1200 ? '400px' : '200px'}
            placement='top'
            textStyle='bodyMdSemi'
            padding='0px 0px 10px 0px'
          />
          <Text textStyle='bodySm'>
            {cardFavoritesText.from} {formatDate(item.createDate)}
          </Text>
        </div>
        <div className={isLargerThan1200 ? 'iconCardLargerThan1200' : 'iconCardSmallerThan1200'}>
          <Icon data-testid='btnDelete' as={Trash} className='iconBtnPrimary' onClick={() => handleDeleteFavorites()} />
          <Icon data-testid='edit-Btn' as={Edit2} className='iconBtnPrimary' onClick={() => handleOnEdit()} />
        </div>
      </div>
      <FavoriteModal isOpen={isOpen} onClose={onClose} alias={item} onDelete={onDelete} />
    </CardContainer>
  );
}

export default FavoriteItem;
