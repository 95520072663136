import { Button, Checkbox, Icon, Menu, MenuButton, Portal, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ChevronDown, RefreshCcw, X } from 'react-feather';

import { getCompaniesReceipt } from '../../services/api';
import { PaymentReceiptsText } from '../../text/GeneralText';
import { Container, ContainerPortal, customButton, CustomMenuList, customMenuList } from './styled';

type FullFilterProps = {
  isDisabled?: boolean;
  filter: (data: object) => void;
};

function FullFilter({ filter, isDisabled = false }: FullFilterProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [company, setCompany] = useState([{ name: '', code: '', isChecked: false }]);
  const [companyFiltered, setCompanyFiltered] = useState([{ name: '', code: '', isChecked: false }]);
  const [counter, setCounter] = useState(0);
  const [name, setName] = useState(PaymentReceiptsText.FilterCompany);
  const [buttonRestoreEnabled, setButtonRetoreEnabled] = useState(false);

  useEffect(() => {
    getCompaniesReceipt().then(res => {
      const listCompany = [];
      res.data.companies.forEach(item => {
        listCompany.push({ name: item.name, code: item.code, isChecked: false });
      });
      setCompany(listCompany);
      setCompanyFiltered(listCompany);
    });
  }, []);

  const handleChangeCheckbox = (isChecked, code) => {
    setCompany(
      company.map(item => {
        if (item.code === code) {
          // Create a *new* object with changes
          return { ...item, isChecked };
        }
        // No changes
        return item;
      }),
    );
  };

  useEffect(() => {
    if (isOpen) {
      setCompany(companyFiltered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setCounter(company.filter(a => a.isChecked).length);
  }, [company]);

  const handleClick = () => {
    const isChecked = false;
    setCompany(
      company.map(item => {
        // Create a *new* object with changes
        return { ...item, isChecked };
      }),
    );
    setButtonEnabled(false);
  };

  const handleApply = () => {
    if (company !== companyFiltered) {
      setCompanyFiltered(company);
      filter(company.filter(a => a.isChecked));
      setName(getName());
      setButtonRetoreEnabled(counter > 0);
    }
    setIsOpen(!isOpen);
  };

  const handleRestore = () => {
    setIsOpen(false);
    filter([]);
    handleClick();
    setCompanyFiltered(
      companyFiltered.map(item => {
        // Create a *new* object with changes
        return { ...item, isChecked: false };
      }),
    );
    setCounter(0);
    setName(PaymentReceiptsText.FilterCompany);
    setButtonRetoreEnabled(false);
  };

  useEffect(() => {
    setButtonEnabled(counter > 0);
  }, [counter]);

  const getName = () => {
    switch (counter) {
      case 0:
        return PaymentReceiptsText.FilterCompany;
      case 1:
        return company.find(a => a.isChecked).name;
      default:
        return `${PaymentReceiptsText.FilterCompany} (${counter})`;
    }
  };

  return (
    <Container>
      <Text textStyle='labelLgBold' className='marginFilter'>
        {PaymentReceiptsText.filter}
      </Text>
      <Menu isOpen={isOpen}>
        <MenuButton
          disabled={company.length < 2 || isDisabled}
          className={name === PaymentReceiptsText.FilterCompany ? 'menuButtonEnabled' : 'menuButtonActive'}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className='flexMenuButton'>
            <Text className={name === PaymentReceiptsText.FilterCompany ? 'textMenuButton' : 'textMenuButtonFiltered'} textStyle='labelLg'>
              {name}
            </Text>
            <ChevronDown className='iconMenuButton' />
          </div>
        </MenuButton>
        <Portal>
          <ContainerPortal>
            {company.length > 1 ? (
              <CustomMenuList sx={customMenuList} zIndex='0px' data-testid='customMenuList'>
                <div className='menuItem'>
                  <Text textStyle='labelLgBold' className='subtitleMenuItem'>
                    {PaymentReceiptsText.subtitleMenuFilter}
                  </Text>
                  <Icon className='iconCloseButton' h={5} w={5} as={X} onClick={() => setIsOpen(!isOpen)} />
                </div>
                <hr className='menuDivider' />
                <div className='menuItemCheckbox'>
                  {company.map(item => (
                    <Checkbox
                      name='checkboxCompany'
                      key={item.code}
                      className={item.isChecked ? 'textCheckboxBlue' : 'textCheckbox'}
                      onChange={e => handleChangeCheckbox(e.target.checked, item.code)}
                      isChecked={item.isChecked}
                    >
                      {item.name}
                    </Checkbox>
                  ))}
                </div>
                <hr className='menuDivider' />
                <div className='menuItem'>
                  <Button onClick={() => handleClick()} isDisabled={!buttonEnabled} variant='primary-text'>
                    {PaymentReceiptsText.MenuCompanyClean}
                  </Button>
                  <Button onClick={() => handleApply()} variant='primary'>
                    {PaymentReceiptsText.MenuCompanyApply}
                  </Button>
                </div>
              </CustomMenuList>
            ) : null}
          </ContainerPortal>
        </Portal>
      </Menu>
      <Button
        onClick={() => handleRestore()}
        isDisabled={!buttonRestoreEnabled}
        leftIcon={<RefreshCcw />}
        variant='primary-text'
        sx={customButton}
      >
        {PaymentReceiptsText.buttonRestore}
      </Button>
    </Container>
  );
}

export default FullFilter;
