/* eslint-disable import/no-unresolved */
import { Button, Icon } from '@chakra-ui/react';
import { pushAnalyticsEvent } from '@wow/utils';
import { memo, useEffect, useState } from 'react';
import { Star } from 'react-feather';

import useChakraToast from '../../../hooks/useChakraToast';
import { IFavoriteButton } from '../../../interfaces/IFavoriteButton';
import { createServiceAlias, getExistingAlias } from '../../../services/api';
import { AliasModalText, SnackbarTexts, ToastTexts } from '../../../text/GeneralText';
import AliasModal from '../../AliasModal';

function FavoriteButton({ modalitiesForm, persistedForm, setFavoriteButtonData }: IFavoriteButton) {
  const { ToastUtilities } = useChakraToast();
  const [aliasToCreate, setAliasToCreate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [disableAliasButton, setDisableAliasButton] = useState(true);

  const handleCreateAliasSuccess = (newServiceAlias: string) => {
    const message = SnackbarTexts?.createAliasSucess(persistedForm.companyName, newServiceAlias);
    setDisableAliasButton(true);
    getAlias();
    ToastUtilities.success({
      description: message,
      id: 'toast-id',
    });
  };

  const retryOnCreate = () => {
    createServiceAlias(aliasToCreate)
      .then(() => {
        setDisableAliasButton(true);
        handleCreateAliasSuccess(aliasToCreate.alias);
      })
      .catch(() => {
        handleCreateAliasError(null, aliasToCreate);
      });
  };
  const handleCreateAliasError = (_, aliasToCreateReceived?) => {
    const message = SnackbarTexts?.createAliasError(persistedForm.companyName);
    ToastUtilities.error({
      description: message,
      id: 'toast-id',
      toastOptions: { ctaLabel: ToastTexts.ctaLabel1, ctaOnClick: () => retryOnCreate() },
    });
    setAliasToCreate(aliasToCreateReceived);
  };

  const getAlias = () => {
    getExistingAlias({
      companyCode: persistedForm.companyId,
      idGireModality: persistedForm.modalityId,
      formNodeAlias: modalitiesForm.fields,
    })
      .then(resp => {
        // TODO crear un archivo de constantes
        setDisableAliasButton(resp.data.code !== '400');
        setFavoriteButtonData(prevState => ({ ...prevState, serviceAlias: resp.data?.alias?.alias }));
      })
      .catch(e => {
        throw e;
      });
    setDisableAliasButton(false);
  };

  useEffect(() => {
    if (modalitiesForm) {
      getAlias();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModal = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones seccion facturas',
      item_id: 'Guardar como favorito',
    });
    setShowModal(prevState => !prevState);
  };

  if (!modalitiesForm?.type || modalitiesForm?.type === 'CLR') return null;

  return (
    <>
      <Button
        variant='primary-outline'
        leftIcon={<Icon as={Star} />}
        isDisabled={disableAliasButton}
        onClick={handleModal}
        size='md'
        id='savePayments'
        data-testid='favoriteButton'
      >
        {AliasModalText.createAlias}
      </Button>

      <AliasModal
        variant='create'
        isOpen={showModal}
        onClose={() => setShowModal(prevState => !prevState)}
        onSaveSuccess={handleCreateAliasSuccess}
        onSaveError={handleCreateAliasError}
      />
    </>
  );
}

export default memo(FavoriteButton);
