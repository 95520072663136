/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/no-unresolved
import { Button, GridItem, Icon } from '@chakra-ui/react';
import { ColumnDef, RowData } from '@tanstack/react-table';
import { TablePaginationComponent } from '@wow/table-pagination';
import { OpenMode, pushAnalyticsEvent } from '@wow/utils';
import { useContext, useEffect, useState } from 'react';
import { Download } from 'react-feather';

import AppContext from '../../context/Provider';
import useChakraToast from '../../hooks/useChakraToast';
import { IVouchers, Pagination as PaginationInterfaces } from '../../interfaces/IAvailableVouchers';
import { downloadReceipt, downloadReceiptZip, getVoucher } from '../../services/api';
import { Buttons, PaymentReceiptsText, SnackbarTexts } from '../../text/GeneralText';
import { dateValueToString } from '../../utils/converters';
import CommonPage from '../CommonPage';
import EmptyStateScreen from '../EmptyStateScreen';
import useCreateNewPayment from '../EmptyStateScreen/useCreateNewPayment';
import FullFilter from '../FullFilter';
import TableComponent from '../TableComponent';
import getPaymentReceiptsColumns from './components/PaymentReceiptsCConfig';
import SkeletonReceipts from './components/SkeletonReceipts';
import { Container, Link } from './styled';

const urlIssuedTransfers = OpenMode({
  module: 'urlComplete',
  urlComplete: `${process.env.URL_DOMAIN_SIB}${process.env.SIB_REPORTES_TEF}`,
});

function PaymentReceipts() {
  const [rowsSelected, setRowsSelected] = useState([]);
  const [pagination, setPagination] = useState<PaginationInterfaces>({ totalItems: 0, itemsByPage: 0, totalPages: 0, currentPage: 0 });
  const [data, setData] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [size, setSize] = useState<number>(15);
  const [sortProp, setSortProp] = useState<string>('fechaEstado');
  const [sortOrder, setSortOrder] = useState<string>('desc');
  const [company, setCompany] = useState<string[]>([]);
  const [hasErrorConnection, setHasErrorConnection] = useState(false);
  const [tempPaymentId, setTempPaymentId] = useState(null);
  const [handleGoNewPayment] = useCreateNewPayment();
  const { permissions } = useContext(AppContext);
  const { ToastUtilities } = useChakraToast();

  useEffect(() => {
    getReceipts();
  }, [page, size, sortProp, sortOrder, company]);

  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Comprobantes',
    });
  }, []);

  //! This is a patch for the table component component 15/11/2024 removed when DS have the fix for this
  useEffect(() => {
    const selectorCenterElement = document.querySelector('[data-testid="select"]');
    if (selectorCenterElement) {
      if (pagination.totalItems <= 15) {
        selectorCenterElement.setAttribute('disabled', '');
      } else {
        selectorCenterElement.removeAttribute('disabled');
      }
    }
  }, [page, pagination.totalItems]);

  const getReceipts = () => {
    setIsLoading(true);
    getVoucher({
      companyService: company,
      dateFrom: '2021-01-01',
      dateTo: dateValueToString(new Date()),
      page: Number(page),
      size: Number(size),
      sortProperty: sortProp,
      sortDirection: sortOrder,
    })
      .then(res => {
        setData(res.data.receiptList);
        setPagination(res.data.pagination);
        setIsLoading(false);
        setHasFetchedData(true);
        setHasErrorConnection(false);
      })
      .catch(() => {
        setHasErrorConnection(true);
        setIsLoading(false);
        setHasFetchedData(true);
      });
  };

  const handleOnClick = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones seccion Comprobantes',
      item_id: 'Ver transferencias emitidas',
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const filterReceipt = (data: [{ code: string; name: string; isChecked: boolean }]) => {
    const list = data.map(item => item.code);
    setPage(0);
    setCompany(list);
  };

  const setSort = (sortPropCol: string, sortOrderCol: string) => {
    setSortProp(sortPropCol);
    setSortOrder(sortOrderCol);
  };

  const handlerDownloadZip = () => {
    const listPayment = [];
    rowsSelected.forEach(row => {
      listPayment.push(row.original.idPayment);
    });

    downloadReceiptZip({
      paymentList: listPayment,
    })
      .then(response => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Comprobantes de pagos.zip`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(() => {
        renderToastError();
      });
  };

  const handlerDlReceipt = paymentId => {
    downloadReceipt(paymentId)
      .then(response => {
        if (response.data) {
          setTempPaymentId(null);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Comprobante de Pago.pdf`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(() => {
        setTempPaymentId(paymentId);
        renderToastError();
      });
  };
  const renderToastError = () =>
    ToastUtilities.error({
      description: SnackbarTexts.downloadFailed,
      id: 'toast-id',
      toastOptions: {
        ctaLabel: Buttons.retryConnection,
        ctaOnClick: () => (tempPaymentId ? handlerDlReceipt(tempPaymentId) : handlerDownloadZip()),
      },
    });
  return (
    <Container>
      <CommonPage
        headline={PaymentReceiptsText.title}
        text={
          <>
            {PaymentReceiptsText.subtitle}{' '}
            <Link target='_blank' href={urlIssuedTransfers} rel='noopener noreferrer' onClick={handleOnClick}>
              {PaymentReceiptsText.linkTitle}
            </Link>
            .
          </>
        }
      />
      <>
        <FullFilter filter={filterReceipt} isDisabled={hasErrorConnection} />
        {isLoading ? (
          <SkeletonReceipts num={4} />
        ) : (
          <>
            {!hasErrorConnection && !isLoading && hasFetchedData && (
              <Button
                variant='primary'
                onClick={handlerDownloadZip}
                leftIcon={<Icon as={Download} />}
                data-testid='zip-btn'
                isDisabled={rowsSelected.length === 0}
              >
                {PaymentReceiptsText.buttonDownload}
              </Button>
            )}
            <GridItem colSpan={12}>
              {!hasErrorConnection ? (
                <>
                  {isLoading || !hasFetchedData ? null : (
                    <TableComponent<IVouchers[]>
                      inputData={data}
                      columnsData={getPaymentReceiptsColumns(setSort, handlerDlReceipt) as ColumnDef<RowData>[]}
                      setSelectedRows={setRowsSelected}
                      emptyContent={
                        data.length === 0 ? (
                          <EmptyStateScreen
                            page='PAYMENT_RECEIPTS'
                            variant='NO_DATA'
                            hasButton={permissions?.generacionPreconfeccionPropia}
                            onRetry={handleGoNewPayment}
                          />
                        ) : null
                      }
                    />
                  )}
                </>
              ) : (
                <EmptyStateScreen page='PAYMENT_RECEIPTS' variant='ERROR_CONN' hasButton onRetry={getReceipts} />
              )}
              {!isLoading && hasFetchedData && data.length > 0 ? (
                <TablePaginationComponent
                  totalPages={pagination.totalPages || 1}
                  currentIndex={pagination.currentPage}
                  totalRecords={pagination.totalItems}
                  perPage={[15, 30, 45]}
                  disabledPerPage={pagination.totalItems <= 15}
                  currentPerPage={pagination.itemsByPage}
                  onNext={() => setPage(page + 1)}
                  onPrev={() => setPage(page - 1)}
                  onFirst={() => setPage(0)}
                  onLast={() => setPage(pagination.totalPages - 1)}
                  onJump={value => setPage(Number(value))}
                  onPerPageChange={value => {
                    setSize(Number(value));
                    setPage(0);
                  }}
                />
              ) : null}
            </GridItem>
          </>
        )}
      </>
    </Container>
  );
}

export default PaymentReceipts;
