/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/no-unresolved
import { useToast } from '@chakra-ui/react';
import { WithPermissions } from '@react-ib-mf/style-guide-ui';
import { ErrorContentComponent, ErrorContentProps } from '@wow/error-content';
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import PaymentReceipts from './components/PaymentReceipts';
import routesNames from './constants/routesNames';
import NAMED_PERMISSIONS from './constants-app';
import Pagos from './layouts/pagos';
import Bills from './views/Bills';
import Cart from './views/Cart';
import Checkout from './views/Checkout';
import CreatePay from './views/CreatePay';
import FavoritePayments from './views/FavoritePayments';
import Home from './views/Home';
import Summary from './views/Summary';

function RouteChangeListener() {
  const location = useLocation();
  const toastRef = useToast();
  const routesWithPersistentToasts = [`/${routesNames.PaymentCart}`];
  useEffect(() => {
    if (!routesWithPersistentToasts.includes(location.pathname)) {
      toastRef.closeAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, toastRef]);

  return null;
}

function App() {
  const ALL_PERMISSIONS = Object.values(NAMED_PERMISSIONS);
  const TRANSFERENCIAS_GENERACION_INT_PRECONFECCIONPROPIAS_AND_TRANSFERENCIAS_GENERACION_INT_CONFECCION = [
    `${NAMED_PERMISSIONS.Transferencias_Generacion_INT_PreconfeccionPropias},${NAMED_PERMISSIONS.Transferencias_Generacion_INT_Confeccion}`,
  ];
  const ErrorContent: ErrorContentProps = {
    titleText: 'No podemos mostrar la página.',
    bodyText: 'Notamos que no tenés permisos para visualizar esta sección.',
    linkText: 'Reintentar',
    callbackAction: () => window.location.reload(),
    accessError: 'content',
    isRouter: false,
  };
  return (
    <Router>
      <RouteChangeListener />
      <Routes>
        <Route
          path='/'
          element={
            <WithPermissions permission={ALL_PERMISSIONS} showErrorPage>
              <Pagos />
            </WithPermissions>
          }
        >
          <Route
            path={routesNames.FavoritePayments}
            element={
              <WithPermissions permission={ALL_PERMISSIONS} showErrorPage>
                <FavoritePayments />
              </WithPermissions>
            }
          />
          <Route
            path={routesNames.ReceiptsPayments}
            element={
              <WithPermissions permission={ALL_PERMISSIONS} showErrorPage>
                <PaymentReceipts />
              </WithPermissions>
            }
          />
          <Route
            path={routesNames.PaymentCart} //
            element={
              <WithPermissions
                permission={TRANSFERENCIAS_GENERACION_INT_PRECONFECCIONPROPIAS_AND_TRANSFERENCIAS_GENERACION_INT_CONFECCION}
                showErrorPage
              >
                <Cart />
              </WithPermissions>
            }
          />
          <Route
            path={routesNames.SummaryPayments}
            element={
              <WithPermissions
                permission={TRANSFERENCIAS_GENERACION_INT_PRECONFECCIONPROPIAS_AND_TRANSFERENCIAS_GENERACION_INT_CONFECCION}
                showErrorPage
              >
                <Summary />
              </WithPermissions>
            }
          />
          <Route
            path={routesNames.CreatePayment}
            element={
              <WithPermissions
                permission={TRANSFERENCIAS_GENERACION_INT_PRECONFECCIONPROPIAS_AND_TRANSFERENCIAS_GENERACION_INT_CONFECCION}
                showErrorPage
              >
                <CreatePay />
              </WithPermissions>
            }
          />
          <Route
            path={routesNames.PaymentBills}
            element={
              <WithPermissions
                permission={TRANSFERENCIAS_GENERACION_INT_PRECONFECCIONPROPIAS_AND_TRANSFERENCIAS_GENERACION_INT_CONFECCION}
                showErrorPage
              >
                <Bills />
              </WithPermissions>
            }
          />
          <Route
            path={routesNames.Home}
            element={
              <WithPermissions permission={ALL_PERMISSIONS} showErrorPage>
                <Home />
              </WithPermissions>
            }
          />
          <Route
            path={routesNames.AccountSelection}
            element={
              <WithPermissions permission={ALL_PERMISSIONS} showErrorPage>
                <Checkout />
              </WithPermissions>
            }
          />
          <Route path='*' element={<ErrorContentComponent ErrorContentProps={ErrorContent} />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
