/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import { Box, Button, Text } from '@chakra-ui/react';
import { sanitize } from 'dompurify';

import IEmptyStateScreen from '../../interfaces/IEmptyStateScreen';
import EMPTY_RESULTS_CONF from './config';
import { Container } from './styled';

function EmptyStateScreen({ hasButton = false, onRetry, page, variant }: IEmptyStateScreen) {
  const noResultConfig = EMPTY_RESULTS_CONF[page][variant];

  return (
    <Container>
      {noResultConfig.picture}
      <Text textStyle='h4Bold' className='empty-state-screen-title'>
        {noResultConfig.title}
      </Text>
      <Text
        textStyle='bodyLg'
        className='empty-state-screen-subtitle'
        dangerouslySetInnerHTML={{ __html: sanitize(noResultConfig.subtitle) }}
      />
      {hasButton && (
        <Box className='empty-state-screen-button'>
          <Button variant='primary-text' onClick={onRetry} size='md'>
            {noResultConfig.buttonLabel}
          </Button>
        </Box>
      )}
    </Container>
  );
}
export default EmptyStateScreen;
