import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import routesNames from '../constants/routesNames';
import useSessionStorage from '../hooks/useSessionStorage';
import { IContextProviderProps } from '../interfaces/IAppContext';
import { IBillsReqBody } from '../interfaces/IModalityForm';
import { TableCheckout } from '../interfaces/ITableCheckout';
import {
  getCartCompanyItems,
  getDataTableCheckout,
  getMaxItems,
  getOperatibility,
  getPermissions,
  postAvailableBills,
} from '../services/api';
import AppContext from './Provider';

function AppContextProvider({ children }: IContextProviderProps) {
  const [billsReqBody, setBillsReqBody] = useState<IBillsReqBody>();
  const [billsData, setBillsData] = useState(null);
  const [isBillsError, setIsBillsError] = useState(false);
  const [isButtonAvailable, setIsButtonAvailable] = useState(null);
  const [bills, setBills] = useSessionStorage('bills', billsData);
  const [cartFull, setCartFull] = useState(false);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [billsPayload, setBillsPayload] = useSessionStorage('billsPayload', {});
  const [modalitiesForm, setModalitiesForm] = useSessionStorage('modalitiesForm', {});
  const [persistedForm, setPersistedForm] = useSessionStorage('persisted-form', {});
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const [maxCartItem, setMaxCartItem] = useState(null);
  const [cartItems, setCartItems] = useState(null);
  const [cartTotalAmount, setcartTotalAmount] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [errorPrepackaging, setErrorPrepackaging] = useState(false);
  const [operative, setOperative] = useState(true);
  const [startOperative, setStartOperative] = useState(null);
  const [endOperative, setEndOperative] = useState(null);
  const [billsPaymentErrors, setBillsPaymentErrors] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [isAddToCartBtnDisabled, setIsAddToCartBtnDisabled] = useState(false);
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const [favoriteButtonData, setFavoriteButtonData] = useState({ serviceAlias: '' });
  const [accountsErrorDataTable, setAccountsErrorDataTable] = useState(false);
  const [paymentCheckoutProcess, setPaymentCheckoutProcess] = useState(false);
  const [temporalCartItems, setTemporalCartItems] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const handleFilters = (key: string, data: Array<string> | string, callback: (args?: Array<string> | string) => void) => {
    setFilterValues(prevFilter => {
      const newFilter = { ...prevFilter, [key]: data };
      const values = Object.values(newFilter).flat() as Array<string>;
      if (callback) {
        callback(values);
      }
      return newFilter;
    });
  };
  const navigate = useNavigate();
  const clearFilterValues = () => setFilterValues({});
  useEffect(() => {
    getMaxItems().then(res => setMaxCartItem(res.data.maxItems));
    getPermissions().then(res => setPermissions(res.data));
  }, []);

  const handleBillsData = useCallback(async () => {
    try {
      const res = await postAvailableBills({ ...billsPayload, fields: modalitiesForm.fields });
      if (res.data) {
        setBillsData(res.data);
        setBills(res.data);
        navigate(routesNames.PaymentBills);
      } else {
        setIsBillsError(true);
        setIsButtonAvailable(false);
      }
    } catch (error) {
      if (error) {
        setIsBillsError(true);
        setIsButtonAvailable(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billsReqBody]);

  const getAccountsData = useCallback(async ({ params }): Promise<TableCheckout> => {
    try {
      const response = await getDataTableCheckout({ params });
      setAccountsErrorDataTable(false);
      if (Object.keys(response.data).length === 0) {
        setAccountsErrorDataTable(true);
      }
      return response.data as TableCheckout;
    } catch (error) {
      setAccountsErrorDataTable(true);
      throw new Error(error);
    }
  }, []);

  const getCartData = useCallback(async () => {
    const res = await getCartCompanyItems();
    const { companies, totalAmount, totalItems } = res.data.cart;
    setCartItems(companies);
    setcartTotalAmount(totalAmount);
    setCartItemsCount(totalItems);
    if (totalItems === 0) {
      setErrorPrepackaging(false);
    }
    return Promise.resolve(companies);
  }, []);

  const validateOperatibility = useCallback(async () => {
    const res = await getOperatibility();
    setOperative(res.data.operability.operative);
    setStartOperative(res.data.operability.start);
    setEndOperative(res.data.operability.end);
    return Promise.resolve(res.data.operability.operative);
  }, []);

  const deleteDataServicePayment = useCallback(() => {
    const billsInputData: IBillsReqBody = { companyCode: '', modalityId: '', fields: {} };
    setBillsPayload(billsInputData);
    setPersistedForm({
      ...persistedForm,
      modalityId: '',
      modalityLabel: '',
      companyId: '',
      companyName: '',
    });
    setModalitiesForm({ ...modalitiesForm, fields: {} });
    setIsButtonAvailable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      setBillsReqBody,
      setBillsPaymentErrors,
      setIsButtonAvailable,
      handleBillsData,
      setIsBillsError,
      setCartFull,
      setRowsSelected,
      setCartItemsCount,
      billsPaymentErrors,
      rowsSelected,
      cartFull,
      isAddToCartBtnDisabled,
      setIsAddToCartBtnDisabled,
      setModalitiesForm,
      setBillsPayload,
      modalitiesForm,
      billsPayload,
      billsReqBody,
      isButtonAvailable,
      billsData,
      bills,
      isBillsError,
      persistedForm,
      setPersistedForm,
      setBills,
      setBillsData,
      cartItemsCount,
      maxCartItem,
      cartItems,
      cartTotalAmount,
      getCartData,
      companyName,
      setCompanyName,
      deleteDataServicePayment,
      setErrorPrepackaging,
      errorPrepackaging,
      operative,
      setOperative,
      startOperative,
      setStartOperative,
      endOperative,
      setEndOperative,
      validateOperatibility,
      permissions,
      isLoadingScreen,
      setIsLoadingScreen,
      favoriteButtonData,
      setFavoriteButtonData,
      getAccountsData,
      accountsErrorDataTable,
      setAccountsErrorDataTable,
      paymentCheckoutProcess,
      setPaymentCheckoutProcess,
      temporalCartItems,
      setTemporalCartItems,
      filterValues,
      clearFilterValues,
      handleFilters,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      setBillsReqBody,
      setBillsPaymentErrors,
      setIsButtonAvailable,
      handleBillsData,
      navigate,
      setIsBillsError,
      setCartItemsCount,
      setCartFull,
      isAddToCartBtnDisabled,
      setIsAddToCartBtnDisabled,
      setRowsSelected,
      rowsSelected,
      cartFull,
      setModalitiesForm,
      setBillsPayload,
      modalitiesForm,
      billsPayload,
      billsReqBody,
      billsPaymentErrors,
      isButtonAvailable,
      billsData,
      isBillsError,
      bills,
      persistedForm,
      setPersistedForm,
      setBills,
      setBillsData,
      cartItemsCount,
      maxCartItem,
      cartItems,
      cartTotalAmount,
      getCartData,
      companyName,
      setCompanyName,
      deleteDataServicePayment,
      setErrorPrepackaging,
      errorPrepackaging,
      operative,
      setOperative,
      startOperative,
      setStartOperative,
      endOperative,
      setEndOperative,
      validateOperatibility,
      permissions,
      isLoadingScreen,
      setIsLoadingScreen,
      favoriteButtonData,
      setFavoriteButtonData,
      getAccountsData,
      accountsErrorDataTable,
      setAccountsErrorDataTable,
      paymentCheckoutProcess,
      setPaymentCheckoutProcess,
      temporalCartItems,
      setTemporalCartItems,
      filterValues,
      clearFilterValues,
      handleFilters,
    ],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export default AppContextProvider;
