import { MenuList } from '@chakra-ui/react';
import styled from 'styled-components';

import { boxShadows, colors, fonts, sizeFont } from '../../styled/generalStyles';

export const Container = styled.div`
  margin: 15px 0px 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 0;

  .marginFilter {
    padding: 14px 30px 14px 16px;
  }

  .flexMenuButton {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .textMenuButton {
    padding: 6px 12px 6px 8px;
  }

  .textMenuButtonFiltered {
    padding: 6px 12px 6px 8px;
    color: ${colors.BlueDark};
  }

  .menuButtonEnabled {
    height: 32px;
    border: 1px solid ${colors.light};
    border-radius: 8px;
    color: ${colors.textgray};
    &:disabled {
      background: ${colors.white};
      color: ${colors.borderGray};
      border: 1px solid ${colors.rowHeaderBackgroundTable};
      border-radius: 8px;
    }
  }

  .menuButtonActive {
    height: 32px;
    background: ${colors.lightBlueNew};
    border-radius: 8px;
    color: ${colors.textgray};
    &:disabled {
      background: ${colors.white};
      color: ${colors.borderGray};
      border: 1px solid ${colors.rowHeaderBackgroundTable};
      border-radius: 8px;
    }
  }

  .iconMenuButton {
    margin-right: 12px;
    width: 18px;
  }

  .menuDivider {
    color: ${colors.gray4};
    margin: 0px 12px 0px 12px;
  }
`;

export const CustomMenuList = styled(MenuList)`
  box-shadow: ${boxShadows.gray};
`;

export const customMenuList = {
  width: '392px',
  boxShadow: `${boxShadows.gray}`,
  borderRadius: '0px',
  borderWidth: '0px',
  padding: '8px 12px 8px 12px',
};

export const ContainerPortal = styled.div`
  .menuItem {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
  }

  .subtitleMenuItem {
    padding: 8px;
  }

  .menuItemCheckbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    max-height: 280px;
    overflow: auto;
  }

  .textCheckbox {
    padding: 10px;
  }

  .textCheckboxBlue {
    padding: 10px;
  }

  .textCheckboxBlue .chakra-checkbox__label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 265px;
    color: ${colors.blue};
    font-family: ${fonts.bodyBold};
    font-size: ${sizeFont.fontSize14};
  }

  .textCheckbox .chakra-checkbox__label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 265px;
    font-size: ${sizeFont.fontSize14};
  }

  .iconCloseButton {
    cursor: pointer;
  }
`;

export const customButton = {
  right: '20px',
  position: 'absolute',
};

export default Container;
