import { Button, GridItem, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { memo, useContext } from 'react';

import AppContext from '../../../context/Provider';
import { CartSummaryTexts } from '../../../text/GeneralText';
import { transformNumberToCurrency } from '../../../utils/converters';
import CheckoutListDrawer from './CheckoutListDrawer';
import {
  CartSummaryBillsButton,
  CartSummaryTotalText,
  ContainerCartSummary,
  ContainerCartSummaryBills,
  ContainerCartSummaryTotal,
  ContainerSup,
} from './styled';

function CartSummary() {
  const { cartTotalAmount, cartItemsCount, cartItems } = useContext(AppContext);
  const [isLessThan1000] = useMediaQuery('(max-width: 1000px)');
  const summaryTotal = CartSummaryTexts.totalToPayAmount(transformNumberToCurrency(cartTotalAmount));
  const summaryTotalInArray = summaryTotal.split(',');
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <ContainerCartSummary data-testid='cart-summary'>
        <ContainerCartSummaryTotal $isLessThan1000={isLessThan1000}>
          <Text>{CartSummaryTexts.totalToPayText}</Text>
          <CartSummaryTotalText $isLessThan1000={isLessThan1000}>
            <Text>
              {summaryTotalInArray[0]} <ContainerSup>{summaryTotalInArray[1]}</ContainerSup>
            </Text>
          </CartSummaryTotalText>
        </ContainerCartSummaryTotal>
        <ContainerCartSummaryBills $isLessThan1000={isLessThan1000}>
          <Text>{CartSummaryTexts.billsToPay(cartItemsCount)}</Text>
          <CartSummaryBillsButton $isLessThan1000={isLessThan1000}>
            <GridItem colEnd={11}>
              <Button className='margin24' size='md' variant='primary-outline' onClick={onOpen}>
                {CartSummaryTexts.seeDetailButton}
              </Button>
            </GridItem>
          </CartSummaryBillsButton>
        </ContainerCartSummaryBills>
      </ContainerCartSummary>
      {isOpen && (
        <CheckoutListDrawer
          {...{ isOpen, onClose, cartItems, billsToPay: CartSummaryTexts.billsToPay(cartItemsCount), summaryTotalInArray }}
        />
      )}
    </>
  );
}

export default memo(CartSummary);
