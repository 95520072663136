/* eslint-disable import/no-unresolved */
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { ColumnDef, RowData } from '@tanstack/react-table';
import { AlertComponent, AlertDescription, AlertTitle } from '@wow/alert';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import { IBill } from '../../interfaces/IAvailableBills';
import { AlertBannerInformation, BillsTableInformation } from '../../text/GeneralText';
import { transformNumberToCurrency } from '../../utils/converters';
import CommonPage from '../CommonPage';
import TableComponent from '../TableComponent';
import getBillsTableColumns from './ColumnsConfig';
import { AvailableBills } from './styled';

function BillsTable() {
  const {
    billsData: data,
    bills,
    persistedForm,
    setPersistedForm,
    cartFull,
    setCartFull,
    rowsSelected,
    setRowsSelected,
    maxCartItem,
    operative,
    startOperative,
    endOperative,
    setIsAddToCartBtnDisabled,
    cartItemsCount,
    validateOperatibility,
    favoriteButtonData,
  } = useContext(AppContext);
  const [finalData, setFinalData] = useState([]);
  const location = useLocation();
  const [, setMissingFact] = useState(null);

  const [isUnacceptableAmount, setIsUnacceptableAmount] = useState(false);
  const [countSelect, setCountSelect] = useState(null);
  const [totalPaymentSelect, setTotalPaymentSelect] = useState(0);
  const navigate = useNavigate();

  // llamar la servicio de maxItems y operatividad
  useEffect(() => {
    validateOperatibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const alertWhenCartFullAndBillSelected = cartItemsCount + countSelect > maxCartItem;

  // habilita - deshabilita agregar al carrito
  useEffect(() => {
    setIsAddToCartBtnDisabled(
      countSelect === 0 || isUnacceptableAmount || cartFull || totalPaymentSelect < 0.01 || alertWhenCartFullAndBillSelected,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countSelect, isUnacceptableAmount, cartFull, totalPaymentSelect]);

  // muestra carrito
  useEffect(() => {
    if (cartItemsCount && maxCartItem) {
      setCartFull(cartItemsCount === maxCartItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItemsCount, maxCartItem]);

  // casi lleno
  useEffect(() => {
    if (cartItemsCount >= 0 && maxCartItem) {
      let addition = 0;
      rowsSelected.forEach(row => {
        if (row.original.enabled === true) {
          addition += 1;
        }
      }, 0);
      setCountSelect(addition);
      setMissingFact(maxCartItem - cartItemsCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItemsCount, maxCartItem, cartFull, rowsSelected]);

  useEffect(() => {
    let addition = 0;
    let inError = 0;
    if (rowsSelected.length > 0) {
      rowsSelected.forEach(row => {
        if (row.original.enabled === true) {
          addition += row.original.amountToPay;
          if (row.original.amountToPay < Number(row.original.amountMin) || row.original.amountToPay > Number(row.original.amountMax)) {
            inError += 1;
          }
        }
      }, 0);
      setTotalPaymentSelect(addition);
      setIsUnacceptableAmount(inError > 0);
    } else {
      setTotalPaymentSelect(addition);
    }
  }, [rowsSelected]);

  // ordenamiento por default de la tabla de mayor a menor segun fecha vencimiento
  useEffect(() => {
    if (data) {
      let sortedData =
        data &&
        data.bills?.sort((a, b) => {
          if (a.expirationDate < b.expirationDate) return 1;
          if (a.expirationDate > b.expirationDate) return -1;
          return 0;
        });
      sortedData = sortedData.map(item => ({ ...item, companyName: persistedForm.companyName }));
      setFinalData(sortedData);
    } else if (bills) {
      let sortedData =
        bills &&
        bills.bills?.sort((a, b) => {
          if (a.expirationDate < b.expirationDate) return 1;
          if (a.expirationDate > b.expirationDate) return -1;
          return 0;
        });
      sortedData = sortedData.map(item => ({ ...item, companyName: persistedForm.companyName })); // eslint-disable-line no-param-reassign
      setFinalData(sortedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, bills]);

  useEffect(() => {
    setPersistedForm({ ...persistedForm, path: location.pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // config usado para definir el total de la tabla facturas disponibles
  function getConfigTot() {
    return {
      // key usado como index para mostrar el total debajo de las columnas indicadas
      4: {
        id: 2,
        value: <Box className='total'>{BillsTableInformation.text}</Box>,
      },
      5: {
        id: 3,
        value: <Box className='total amount '>{transformNumberToCurrency(totalPaymentSelect)}</Box>,
      },
    };
  }

  const getAlertComponentBill = () => {
    let title = '';
    let description = '';

    if (!operative) {
      title = AlertBannerInformation.error.operatibilityTitle;
      description = AlertBannerInformation.error.operatibilityDescription(startOperative, endOperative);
    } else if (cartFull || alertWhenCartFullAndBillSelected) {
      title = AlertBannerInformation.error.titleCartFull;
      description = AlertBannerInformation.error.descriptionCartFull(maxCartItem);
    } else {
      return null;
    }
    return (
      <AlertComponent
        buttonLabel={cartFull ? AlertBannerInformation.error.goToCart : ''}
        onClick={() => navigate(`/${routesNames.PaymentCart}`)}
        id='alert-bills'
        variant='destructive'
        width='100%'
        marginBottom='24px'
        marginTop='10px'
      >
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>{description}</AlertDescription>
      </AlertComponent>
    );
  };

  // Si gap esta en true deja un espacio entre el header y el body de la tabla
  return (
    <AvailableBills>
      <CommonPage
        headline={BillsTableInformation.title}
        subheader={`${BillsTableInformation.titleMyBills} ${persistedForm.companyName} ${
          favoriteButtonData.serviceAlias ? ` - ${favoriteButtonData.serviceAlias}` : ''
        }`}
        text={BillsTableInformation.subtitle}
      />
      <Grid templateColumns='repeat(12, 1fr)' columnGap={16} rowGap={0}>
        <GridItem colSpan={12}>{getAlertComponentBill()}</GridItem>
        <GridItem colSpan={12} className='container'>
          <TableComponent<IBill[]>
            key={favoriteButtonData.serviceAlias}
            inputData={finalData}
            columnsData={getBillsTableColumns(favoriteButtonData.serviceAlias, operative) as ColumnDef<RowData>[]}
            setSelectedRows={setRowsSelected}
            totalRow={getConfigTot()}
          />
        </GridItem>
      </Grid>
    </AvailableBills>
  );
}

export default BillsTable;
