import { Icon } from '@chakra-ui/react';
import { Download } from 'react-feather';

import ColumnSort from '../../../commons/ColumnSort';
import { IVouchers } from '../../../interfaces/IAvailableVouchers';
import { ColumnsConfigTable } from '../../../interfaces/ITableComponent';
import { ProofPayment } from '../../../text/GeneralText';
import { dateValueToStringDDMMYYYY, transformStringToCurrency } from '../../../utils/converters';
import RowSelectCheckbox from '../../Checkbox/RowSelectCheckbox';
import TextEllipsisWithTooltip from '../../TextEllipsisWithTooltip';
import { AmountPaid, CompanyName, iconDl } from '../styled';

// Columnas
export default (sortFunction, handlerDlReceipt): ColumnsConfigTable<IVouchers>[] => [
  {
    id: 'select',
    header: ({ table }) => (
      <RowSelectCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
          isSomeSelected: table.getIsSomeRowsSelected(),
          id: 'head',
        }}
      />
    ),
    cell: ({ row }) => (
      <RowSelectCheckbox
        {...{
          checked: row.getIsSelected(),
          onChange: row.getToggleSelectedHandler(),
        }}
      />
    ),
  },
  {
    accessorKey: 'company',
    id: 'EmpresaServicio',
    header: () => <ColumnSort onSort={order => sortFunction('empresa', order)}>{ProofPayment.columns.company}</ColumnSort>,
    cell: info => <CompanyName>{info.getValue()}</CompanyName>,
    enableSorting: false,
    classNameCell: 'alignStart',
  },
  {
    accessorKey: 'alias',
    id: 'NombreAsignado',
    header: ProofPayment.columns.assignedName,
    cell: info => (
      <TextEllipsisWithTooltip
        text={info.getValue() ? (info.getValue() as string) : '-'}
        placement='top'
        textStyle='body.md'
        padding='0px'
        maxWidth={315}
      />
    ),
    classNameCell: 'alignStart',
    align: 'start',
  },
  {
    accessorKey: 'invoiceNumber',
    id: 'Código de factura',
    header: ProofPayment.columns.billCode,
    cell: info => (
      <TextEllipsisWithTooltip
        text={info.getValue() ? (info.getValue() as string) : '-'}
        placement='top'
        textStyle='body.md'
        padding='0px'
      />
    ),
    classNameCell: 'alignStart',
    align: 'start',
  },
  {
    accessorKey: 'statusDate',
    id: 'FechaPago',
    header: () => <ColumnSort onSort={order => sortFunction('fechaEstado', order)}>{ProofPayment.columns.paymentDate}</ColumnSort>,
    cell: info => <span> {dateValueToStringDDMMYYYY(new Date(info.getValue() as string))}</span>,
    enableSorting: false,
    classNameCell: 'alignStart',
  },
  {
    accessorFn: row => row.amount?.toString(),
    id: 'Pagado',
    header: ProofPayment.columns.ammountPaid,
    cell: info => <AmountPaid>{info.getValue() ? transformStringToCurrency(info.getValue() as string) : '-'}</AmountPaid>,
    classNameCell: 'alignEnd',
    align: 'end',
  },
  {
    accessorFn: payment => payment.idPayment?.toString(),
    id: 'Descargar',
    header: '',
    cell: data => (
      <Icon
        as={Download}
        aria-label='Download'
        onClick={() => handlerDlReceipt(data.getValue())}
        w={5}
        h={5}
        _checked={{ fill: '#0f0' }}
        style={iconDl}
        data-testid='download-pdfBtn'
      />
    ),
  },
];
