import { flexRender, getCoreRowModel, getSortedRowModel, RowData, SortingState, useReactTable } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';

import { INextExpiration } from '../../interfaces/INextExpirationsBills';
import { TableProps } from '../../interfaces/ITableComponent';
import Table from './styled';

function TableComponent<T>({
  inputData,
  columnsData,
  setSelectedRows,
  totalRow,
  emptyContent,
  rowsSelectedBefore,
  isClearSelection,
  isMultiRowSelectionEnabled = true,
  isHeaderHidden = false,
}: TableProps<T>) {
  const [rowSelection, setRowSelection] = useState({});
  const [columns] = useState<typeof columnsData>(() => [...columnsData]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const { getHeaderGroups, getRowModel, getSelectedRowModel } = useReactTable({
    data: inputData ? (inputData as unknown as RowData[]) : [],
    columns,
    state: {
      rowSelection,
      sorting,
    },
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onStateChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    enableMultiRowSelection: isMultiRowSelectionEnabled,
  });

  // mostrar las filas seleccionadas por consola
  useEffect(() => {
    setSelectedRows(getSelectedRowModel().flatRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection, getSelectedRowModel]);

  useEffect(() => {
    if (isClearSelection) setRowSelection({});
  }, [isClearSelection]);

  useEffect(() => {
    if (rowsSelectedBefore?.size > 0) {
      let rowSelectionLocal = rowSelection;

      getRowModel().rows.forEach(rowModel => {
        const original = rowModel.original as INextExpiration;
        if (rowsSelectedBefore.has(original.hash)) {
          rowSelectionLocal = { ...rowSelectionLocal, [rowModel.index]: true };
        }
      });

      setRowSelection(rowSelectionLocal);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trCell = cell => {
    const flexRenderBodyTable = flexRender(cell.column.columnDef.cell, cell.getContext());
    return (
      <td key={cell.id} className={`${cell.column.columnDef.classNameCell || ''}`}>
        {flexRenderBodyTable}
      </td>
    );
  };

  const thCellHeader = header => {
    const flexRenderHeaderTable = header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext());

    return (
      <th key={header.id} className={`${header.column.columnDef.className || ''}`} align={header.column.columnDef.align || 'center'}>
        {flexRenderHeaderTable}
      </th>
    );
  };

  return (
    <Table isHeaderHidden={isHeaderHidden}>
      <thead style={{ display: isHeaderHidden ? 'none' : 'table-header-group' }}>
        {getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id} className='grid-container'>
            {headerGroup.headers.map(header => thCellHeader(header))}
          </tr>
        ))}
      </thead>
      <tbody>
        {getRowModel().rows.map(row => (
          <tr key={`${row.id}_${row.index}`} className='grid-container'>
            {row.getVisibleCells().map(cell => trCell(cell))}
          </tr>
        ))}
        {inputData.length === 0 && (
          <tr className='emptyContent'>
            <td colSpan={columnsData.length}>{emptyContent}</td>
          </tr>
        )}
        {totalRow ? (
          <tr className='disableHover'>
            {getHeaderGroups().map(headerGroup =>
              headerGroup.headers.map((_, index) =>
                totalRow[index] ? (
                  <td key={totalRow[index].id} className='divider'>
                    {totalRow[index].value}
                  </td>
                ) : (
                  <td className='divider' />
                ),
              ),
            )}
          </tr>
        ) : null}
      </tbody>
    </Table>
  );
}

export default TableComponent;
