import { FormControl, FormHelperText, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';

import AppContext from '../../context/Provider';
import { colors, labelText } from '../../styled/generalStyles';
import { DataFilterInformation, DataSelectInformation } from '../../text/GeneralText';
import Styled from './styled';

MainSelect.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dataSelect: PropTypes.object,
  parentSend: PropTypes.func,
  initValue: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
};

function MainSelect({ parentSend, dataSelect, initValue }) {
  const { persistedForm, setPersistedForm, setModalitiesForm, modalitiesForm } = useContext(AppContext);
  const [modalities, setModalities] = useState([{ value: '', label: '' }]);
  const [selectModality, setSelectModality] = useState(null);
  const [value, setValue] = useState(initValue);

  const handleValue = item => {
    if (item) {
      setValue(item);
      parentSend(item.value);
      setSelectModality(item);
      setPersistedForm({ ...persistedForm, modalityId: item.value, modalityLabel: item.label });
      setModalitiesForm({ ...modalitiesForm, fields: {} });
    }
  };

  useEffect(() => {
    if (dataSelect) {
      setModalities(
        dataSelect.modalities.map(item => {
          return { value: item.id, label: item.name };
        }),
      );
    }
  }, [dataSelect]);

  return (
    <FormControl>
      {selectModality && <FormHelperText style={labelText}>{DataSelectInformation.placeholder}</FormHelperText>}
      <Select
        options={modalities}
        placeholder={
          <div>
            <Text textStyle='placeholder'>
              {persistedForm?.modalityLabel ? persistedForm.modalityLabel : DataSelectInformation.placeholder}
            </Text>
          </div>
        }
        value={value}
        styles={Styled}
        onChange={handleValue}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'var(--chakra-colors-accent-500)',
            neutral20: colors.light,
            neutral30: colors.light,
            neutral40: colors.light,
          },
        })}
        noOptionsMessage={() => {
          return DataFilterInformation.noInformation;
        }}
      />
    </FormControl>
  );
}

export default MainSelect;
