/* eslint-disable react/prop-types */
import { Box, CloseButton, Flex, Menu, MenuButton, MenuDivider, MenuGroup, Text } from '@chakra-ui/react';
import { Tag } from '@wow/tag';
import { ComponentType, memo, useEffect } from 'react';

import useFilter from '../../hooks/useFilter';
import { BodyFilterMenuProps, FooterFilterMenuProps } from '../../interfaces/IAccountSelectionFilter';
import BodyFilterMenu from '../CheckoutList/component/BodyFilterMenu';
import FooterFilterMenu from '../CheckoutList/component/FooterFilterMenu';
import { UPDATE_SELECTED_ITEMS } from './constants';
import { ContainerMenu, ContainerMenuList } from './styled';

interface IWithFilter<T> {
  Body?: ComponentType<BodyFilterMenuProps<T>>;
  Footer?: ComponentType<FooterFilterMenuProps>;
  id?: string;
  filterLabel: string;
  data: Array<T>;
  inPromise?: boolean;
  isDisabled?: boolean;
  handleSendInfo: (item: []) => void;
  resetInitialState: boolean;
  titleMenu: string;
  filterTitle?: string;
}

function WithFilter<T>({
  Body = BodyFilterMenu,
  Footer = FooterFilterMenu,
  id,
  filterLabel,
  data,
  inPromise,
  isDisabled,
  handleSendInfo,
  titleMenu,
  resetInitialState,
  filterTitle,
}: IWithFilter<T>) {
  const { state, dispatch, handleClickOption, deleteSelectedItemsTemp, handleInnerChangesTag, deleteAllFilters } = useFilter({});
  const { selectedItems, tempSelectedItems, count } = state;

  useEffect(() => {
    if (resetInitialState) {
      deleteAllFilters();
    }
  }, [deleteAllFilters, resetInitialState]);

  const handleSubmit = async ({ onClose }) => {
    if (!selectedItems?.length && !tempSelectedItems?.length && onClose) {
      onClose();
      return;
    }
    dispatch({ type: UPDATE_SELECTED_ITEMS, payload: { count: selectedItems?.length, tempSelectedItems: selectedItems } });
    try {
      await handleSendInfo(selectedItems);
    } catch (error) {
      throw new Error(error);
    } finally {
      if (onClose) onClose();
    }
  };

  return (
    <ContainerMenu>
      <Menu closeOnSelect={false}>
        {({ isOpen, onClose }) => (
          <>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              {filterTitle && (
                <Text textStyle='Inter' marginRight='12px'>
                  {filterTitle}
                </Text>
              )}
              <Tag
                component='filter'
                onClick={() => handleInnerChangesTag({ onClose })}
                size='lg'
                count={count}
                isSelected={count > 0 && !isOpen}
                isOpen={isOpen}
                label={filterLabel}
                isDisabled={isDisabled}
                as={MenuButton}
                id={id}
              />
            </Box>
            <ContainerMenuList className='menu-list' isLazy>
              <MenuGroup>
                <Flex alignItems='center' justifyContent='space-between'>
                  <Text size='xs' as='b'>
                    {titleMenu}
                  </Text>
                  <CloseButton onClick={onClose} size='md' />
                </Flex>
              </MenuGroup>
              <MenuDivider />
              <MenuGroup>
                <Body {...{ handleClickOption, selectedItems, dataList: data }} />
              </MenuGroup>
              <MenuDivider />
              <Footer
                handleSendInfo={() => handleSubmit({ onClose })}
                {...{ deleteSelectedItems: deleteSelectedItemsTemp, isDisabledButtonDelete: !selectedItems?.length, inPromise }}
              />
            </ContainerMenuList>
          </>
        )}
      </Menu>
    </ContainerMenu>
  );
}

export default memo(WithFilter);
