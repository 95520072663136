/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-danger */
import { Button, Icon, Text, Tooltip } from '@chakra-ui/react';
import { pushAnalyticsEvent } from '@wow/utils';
import { useContext, useState } from 'react';
import { ChevronRight } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import BewareImage from '../../assets/images/Beware';
import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import { IBillPaymentError, IBillPaymentErrorGroup } from '../../interfaces/IAvailableBills';
import { IButton } from '../../interfaces/IButtonPayCart';
import { Company, IBill } from '../../interfaces/ICarts';
import { deleteItemsFromCart, getConfection, getConfectionStatus, getOperatibility, paymentPrepackaging } from '../../services/api';
import { ModalTextsCheckoutPayButton, TooltipTexts } from '../../text/GeneralText';
import { ContainerModalPayInCheckout } from '../CheckoutList/component/styled';
import ConfirmationModal from '../ConfirmationModal';
import { Title } from '../ConfirmationModal/styled';

function PreparePaymentButtonInCheckout({ buttonText }: IButton) {
  const {
    operative,
    setIsLoadingScreen,
    accountsErrorDataTable,
    rowsSelected,
    startOperative,
    endOperative,
    cartItems,
    setCartFull,
    setBillsPaymentErrors,
    setEndOperative,
    setOperative,
    setStartOperative,
    isLoadingScreen,
    getCartData,
    setTemporalCartItems,
    setPaymentCheckoutProcess,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const SECOND_OF_TIMEOUT = 5000;
  const TIME_OUT_CALL_COUNT = 10;

  const confirm = async () => {
    pushAnalyticsEvent({
      event: 'pagar_facturas',
      content_group: 'Pagos - Selección de cuenta',
    });
    try {
      setIsLoadingScreen(true);
      const res = await getOperatibility();
      const { operative: operativeCurrent, start, end } = res?.data?.operability || {};
      if (!operativeCurrent) {
        setOperative(operativeCurrent);
        setStartOperative(start);
        setEndOperative(end);
        throw new Error('non-operational hours');
      } else {
        await validatePayment();
        await updateCartData();
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoadingScreen(false);
    }
  };

  const updateCartData = async () => {
    await getCartData();
    setCartFull(false);
  };

  const handleBillErrors = async ({ errors, bills, results }) => {
    if (Object.keys(errors).length > 0) {
      try {
        fillValuesFromError(errors, bills, results);
        await deleteItemsFromCart({ hashes: errors.hashes });
        setCartFull(false);
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const getBillData = () => {
    const bills = [];
    cartItems?.forEach((company: Company) =>
      company?.bills?.forEach((bill: IBill) => {
        bills.push(bill);
      }),
    );
    return bills;
  };

  const validatePayment = async () => {
    try {
      const bills = getBillData();
      const {
        data: { billsWithErrorInPrepare = { hashes: [] }, billsInvalidForPay = { hashes: [] } },
      } = await paymentPrepackaging({ bills: bills.map(bill => ({ hash: bill.hash, amount: bill.amount })) });
      setTemporalCartItems(cartItems);

      const errorsPrepareList: IBillPaymentError[] = [];
      const errorsInvalidForPays: IBillPaymentError[] = [];
      await handleBillErrors({ errors: billsWithErrorInPrepare, bills, results: errorsPrepareList });
      await handleBillErrors({ errors: billsInvalidForPay, bills, results: errorsInvalidForPays });

      const tempBillsPaymentError: IBillPaymentErrorGroup = {
        prepare: errorsPrepareList,
        invalidForPay: errorsInvalidForPays,
        ok: [],
        confectionError: [],
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { data } = await confirmPayment();
      tempBillsPaymentError.ok = data.confectionsOK || [];
      tempBillsPaymentError.confectionError = data.confectionsError;
      setBillsPaymentErrors({ ...tempBillsPaymentError });
      navigate(`${routesNames.SummaryPayments}`);
    } catch (error) {
      if (error.message === 'Error: time limit') setIsOpenModal(prev => !prev);
      throw new Error(error);
    }
  };

  const fillValuesFromError = (errors, bills: IBill[], result: IBillPaymentError[]) => {
    errors.hashes.forEach(ePrepare => {
      bills.forEach((bill: IBill) => {
        if (bill.hash === ePrepare) {
          result.push({
            alias: bill.aliasName,
            company: bill.company,
            hash: bill.hash,
            amount: bill.amount,
            expiration: bill.expiration,
            reference: bill.bill,
          });
        }
      });
    });
  };

  const handlePromiseWithSetTimeout = async ({ service, timeoutLimit = SECOND_OF_TIMEOUT, callLimit = TIME_OUT_CALL_COUNT }) => {
    let countLimit = 0;
    return new Promise((resolve, reject) => {
      const intervalId = setInterval(() => {
        (async () => {
          try {
            if (countLimit === callLimit) throw new Error('time limit');
            const res = await service();
            if (res.data.isEnded) {
              resolve(res);
              clearInterval(intervalId);
            }
          } catch (error) {
            clearInterval(intervalId);
            reject(error);
          } finally {
            countLimit += 1;
          }
        })();
      }, timeoutLimit);
    });
  };

  const confirmPayment = async () => {
    try {
      const {
        data: { uuid },
      } = await getConfection({ debitAccountId: rowsSelected?.[0]?.original?.accountId });
      return await handlePromiseWithSetTimeout({
        service: () => getConfectionStatus({ uuid }),
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleDeleteCart = async () => {
    try {
      const bills = getBillData();
      const hashesFromBills = bills?.map(bill => bill.hash);
      await deleteItemsFromCart({ hashes: hashesFromBills });
      await updateCartData();
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsOpenModal(prev => !prev);
      setPaymentCheckoutProcess(prev => !prev);
      navigate(`/${routesNames.Home}`);
    }
  };
  return (
    <>
      <Tooltip hasArrow placement='left' isDisabled={operative} label={TooltipTexts.checkboxDisabledBills(startOperative, endOperative)}>
        <Button
          variant='primary'
          onClick={confirm}
          rightIcon={<Icon as={ChevronRight} />}
          isDisabled={!operative || !rowsSelected?.length || accountsErrorDataTable || isLoadingScreen}
          data-testid='checkout-pay'
          size='md'
        >
          {buttonText}
        </Button>
      </Tooltip>
      <ConfirmationModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(prev => !prev)}
        ModalContentCss={ContainerModalPayInCheckout}
        fn={handleDeleteCart}
        acceptButton={ModalTextsCheckoutPayButton.acceptButton}
        handleClickButtonClose={handleDeleteCart}
      >
        <BewareImage />
        <Title>{ModalTextsCheckoutPayButton.title}</Title>
        <Text textStyle='body' dangerouslySetInnerHTML={{ __html: ModalTextsCheckoutPayButton.description }} />
      </ConfirmationModal>
    </>
  );
}

export default PreparePaymentButtonInCheckout;
