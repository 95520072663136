/* eslint-disable import/no-unresolved */
import { Box, Button, Grid, Icon, Text, Tooltip } from '@chakra-ui/react';
import { pushAnalyticsEvent } from '@wow/utils';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useLocation, useNavigate } from 'react-router-dom';

import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import useChakraToast from '../../hooks/useChakraToast';
import { createCartItem, createCartItemList, createServiceAlias } from '../../services/api';
import { BottomNavTexts, Buttons, DataCartInformation, SnackbarTexts, TooltipTexts } from '../../text/GeneralText';
import { transformNumberToCurrency } from '../../utils/converters';
import EmptyCartButton from '../EmptyCartButton';
import PreparePaymentButton from '../PreparePaymentButton';
import PreparePaymentButtonInCheckout from '../PreparePaymentButtonInCheckout';
import FavoriteButton from './FavoriteButton';
import { Container } from './styled';
import generateAliasName from './util';

const SELECTED_INITIAL_ROW = 0;
function Footer() {
  const { ToastUtilities } = useChakraToast();
  const {
    isButtonAvailable,
    isBillsError,
    setIsButtonAvailable,
    handleBillsData,
    setIsBillsError,
    rowsSelected,
    cartItemsCount,
    isAddToCartBtnDisabled,
    operative,
    startOperative,
    endOperative,
    validateOperatibility,
    cartTotalAmount,
    getCartData,
    setFavoriteButtonData,
    modalitiesForm,
    persistedForm,
    favoriteButtonData,
    maxCartItem,
    setPaymentCheckoutProcess,
    permissions,
  } = useContext(AppContext);

  const [totalPay, setTotalPay] = useState(0);
  const location = useLocation();
  const buttonInPromise = useRef(null);
  const navigate = useNavigate();
  const cartFullInPaymentBills =
    (isAddToCartBtnDisabled && cartItemsCount >= maxCartItem) || (rowsSelected && cartItemsCount + rowsSelected.length > maxCartItem);

  const tooltipTextPaymentBills = useMemo(
    () => (cartFullInPaymentBills ? BottomNavTexts.disabledButton : TooltipTexts.buttonAddCartDisabled),
    [cartFullInPaymentBills],
  );

  useEffect(() => {
    if (rowsSelected?.length > SELECTED_INITIAL_ROW) {
      getCartData();
    }

    setTotalPay(cartTotalAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsSelected]);

  const handleAddToCart = async () => {
    pushAnalyticsEvent({
      event: 'add_to_cart',
      content_group: 'Pagos - Crea tu pago',
      item_id: 'Ir a carrito',
    });
    /* eslint-disable react/jsx-props-no-spreading */
    try {
      buttonInPromise.current.disabled = true;
      const operativeTerminal = await validateOperatibility();
      if (operativeTerminal) {
        const addToCartRequests = [];
        rowsSelected.forEach(row => {
          if (row.original.enabled) {
            addToCartRequests.push(createCartItem(row.original.hash, { amount: row.original.amountToPay }));
          }
        });
        if (modalitiesForm?.type && modalitiesForm?.type !== 'CLR' && !favoriteButtonData.serviceAlias) {
          const aliasToCreate = {
            alias: generateAliasName(),
            companyCode: persistedForm.companyId,
            formNodeAlias: modalitiesForm.fields,
            idGireModality: persistedForm.modalityId,
          };
          const responseCreateServiceAlias = await createServiceAlias(aliasToCreate);
          const OK_STATUS = 200;
          if (responseCreateServiceAlias && responseCreateServiceAlias.status === OK_STATUS) {
            ToastUtilities.success({
              id: 'toast-id',
              description: SnackbarTexts.createDefaultAlias(persistedForm.companyName),
            });
          }
        }

        await createCartItemList(addToCartRequests);
        setPaymentCheckoutProcess(false);
        navigate(`/${routesNames.PaymentCart}`, { state: 'factura' });
      }
    } catch (error) {
      ToastUtilities.error({
        id: 'toast-id',
        description: SnackbarTexts.createDefaultAliasError(persistedForm.companyName),
      });
      throw new Error(error);
    } finally {
      buttonInPromise.current.disabled = false;
    }
  };

  const handleGoToBillsPage = async () => {
    try {
      buttonInPromise.current.disabled = true;
      await handleBillsData();
    } catch (error) {
      setIsButtonAvailable(false);
      throw new Error(error);
    } finally {
      buttonInPromise.current.disabled = true;
    }
  };

  const handleGoBack = () => {
    navigate(`${routesNames.CreatePayment}`);
    if (isBillsError) {
      setIsBillsError(false);
    }
  };

  const handleGoBackHome = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones seccion facturas',
      item_id: 'Volver a pagos',
    });
    navigate(`/${routesNames.Home}`);
  };

  const renderSwitch = path => {
    switch (path) {
      case `/${routesNames.PaymentBills}`:
        if (location.state === 'favorite') {
          return buttonNav(Buttons.backToHome, handleGoBackHome);
        }
        return buttonNav(Buttons.back, handleGoBack);
      case `/${routesNames.CreatePayment}`:
      case `/${routesNames.PaymentCart}`:
        return buttonNav(Buttons.backToHome, handleGoBackHome);
      case `/${routesNames.AccountSelection}`:
        if (!operative) {
          return buttonNav(Buttons.backToHome, handleGoBackHome);
        }
        return buttonNav(Buttons.back, () => navigate(`/${routesNames.PaymentCart}`));
      default:
        return null;
    }
  };

  const buttonNav = (buttonText, handleClick) => {
    return (
      <Button size='md' variant='primary-text' leftIcon={<Icon as={ChevronLeft} />} onClick={handleClick}>
        {buttonText}
      </Button>
    );
  };
  if ((!permissions?.generacionPreconfeccionPropia || !cartItemsCount) && location.pathname === `/${routesNames.AccountSelection}`)
    return null;

  return (
    <Container>
      <Grid templateColumns='repeat(12, 1fr)' columnGap={16} rowGap={0} className='heightGrid'>
        <div className='buttonLeft'>
          {renderSwitch(location.pathname)}
          {location.pathname === `/${routesNames.PaymentCart}` && cartItemsCount > 0 ? (
            <>
              <Text textStyle='labelLgBold' className='marginTotal'>
                {DataCartInformation.totalPay} {transformNumberToCurrency(totalPay)}
              </Text>
              {cartItemsCount > 1 ? (
                <Text textStyle='labelLg'>
                  {cartItemsCount} {DataCartInformation.totalBills}
                </Text>
              ) : (
                <Text textStyle='labelLg'>
                  {cartItemsCount} {DataCartInformation.totalBill}
                </Text>
              )}
            </>
          ) : null}
        </div>
        {location.pathname === `/${routesNames.CreatePayment}` ? (
          <div className='buttonRight'>
            <Button
              ref={buttonInPromise}
              id='button_seeBills'
              isDisabled={!isButtonAvailable}
              variant='primary'
              rightIcon={<Icon as={ChevronRight} />}
              onClick={handleGoToBillsPage}
              size='md'
            >
              {Buttons.seeBills}
            </Button>
          </div>
        ) : null}
        {location.pathname === `/${routesNames.PaymentBills}` ? (
          <div className='buttonRight'>
            {!operative ? (
              <Box gap={8} display='flex'>
                <FavoriteButton {...{ modalitiesForm, setFavoriteButtonData, persistedForm }} />

                <Tooltip hasArrow placement='left' label={TooltipTexts.buttonAddCart(startOperative, endOperative)}>
                  <Button size='md' isDisabled variant='primary' rightIcon={<Icon as={ChevronRight} />}>
                    {Buttons.addToCart}
                  </Button>
                </Tooltip>
              </Box>
            ) : (
              <Box gap={8} display='flex'>
                <FavoriteButton {...{ modalitiesForm, setFavoriteButtonData, persistedForm }} />

                <Tooltip isDisabled={!isAddToCartBtnDisabled} hasArrow placement='top' label={tooltipTextPaymentBills}>
                  <Button
                    ref={buttonInPromise}
                    isDisabled={isAddToCartBtnDisabled}
                    variant='primary'
                    rightIcon={<Icon as={ChevronRight} />}
                    onClick={handleAddToCart}
                    size='md'
                    data-testid='addToCartButton'
                  >
                    {Buttons.addToCart}
                  </Button>
                </Tooltip>
              </Box>
            )}
          </div>
        ) : null}
        {location.pathname === `/${routesNames.PaymentCart}` ? (
          <div className='buttonRight'>
            {cartItemsCount > 0 ? <EmptyCartButton /> : null}
            {cartItemsCount !== 0 && <PreparePaymentButton buttonText={Buttons.preparePayment} />}
          </div>
        ) : null}
        {location.pathname === `/${routesNames.AccountSelection}` ? (
          <div className='buttonRight'>
            <PreparePaymentButtonInCheckout buttonText={Buttons.pay} />
          </div>
        ) : null}
      </Grid>
    </Container>
  );
}

export default Footer;
