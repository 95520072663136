import { GoCreatePaymentButtonTexts } from '../text/GeneralText';

export const transformStringToCurrency = (field: string): string => {
  return Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
  }).format(parseFloat(field));
};

export const transformStringToDate = (date: string): string => {
  const partDate = Array.from(date.split('-'));
  const myDate = [partDate[2], partDate[1], partDate[0]].join('/');
  return myDate;
};

export const transformNumberToCurrency = (field: number): string => {
  return Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
  }).format(field);
};

export const transformStringToNumber = (valueString: string) => {
  if (valueString) {
    let numberString = valueString;
    numberString = numberString.replace('$', '');
    numberString = numberString.replace(/\./g, '');
    numberString = numberString.replace(',', '.');
    return Number(numberString);
  }
  return 0;
};

export function stringToDate(date: string) {
  const dateParts = date.split('/');
  const dateTransformed = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
  try {
    return new Date(dateTransformed.toISOString());
  } catch (e) {
    if (e) {
      return '';
    }
  }
  return '';
}

export function dateValueToString(datepickerValue: Date): string {
  let ldFechaHora: Date = datepickerValue;
  ldFechaHora = new Date(ldFechaHora.getTime());
  const anio = ldFechaHora.getFullYear();
  const mes = `0${ldFechaHora.getMonth() + 1}`.slice(-2);
  const dia = `0${ldFechaHora.getDate()}`.slice(-2);
  return `${anio}-${mes}-${dia}`;
}

export function dateValueToStringDDMMYYYY(datepickerValue: Date): string {
  let ldFechaHora: Date = datepickerValue;
  ldFechaHora = new Date(ldFechaHora.getTime());
  const anio = ldFechaHora.getFullYear();
  const mes = `0${ldFechaHora.getMonth() + 1}`.slice(-2);
  const dia = `0${ldFechaHora.getDate()}`.slice(-2);
  return `${dia}/${mes}/${anio}`;
}

export function dateValueToStringDDMMYY(date: Date): string {
  let ldFechaHora: Date = date;
  ldFechaHora = new Date(ldFechaHora.getTime());
  const anio = ldFechaHora.getFullYear().toString().slice(-2);
  const mes = `0${ldFechaHora.getMonth() + 1}`.slice(-2);
  const dia = `0${ldFechaHora.getDate()}`.slice(-2);
  return `${dia}/${mes}/${anio}`;
}

export function arrayToText(array: string[], separator: string, lastSeparator: string): string {
  const finalItem = array.pop();
  return array.length ? array.join(separator) + lastSeparator + finalItem : finalItem;
}

export function capitalizeFirst(str) {
  const text = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  return text;
}
export const renderContentTextButton = (param: string): string => {
  const renderContentButton: Record<string, () => string> = {
    '/pagos/favoritos': () => GoCreatePaymentButtonTexts.btnAddToFavorite,
  };

  return (renderContentButton[param] || (() => GoCreatePaymentButtonTexts.btnCreatePayment))();
};

export function renameKeys<T, K extends keyof T>(obj: T, newKeys: Partial<Record<K, string>>): Partial<T> {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key as K] || key;
    return { [newKey]: obj[key as K] };
  });
  return Object.assign({}, ...keyValues) as Partial<T>;
}
