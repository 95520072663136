import styled from '@emotion/styled';

import { colors, fonts, sizeFont } from '../../styled/generalStyles';

interface StyledComponentProps {
  isHeaderHidden?: boolean;
}
const Table = styled.table<StyledComponentProps>`
  border-collapse: ${props => (props.isHeaderHidden ? 'collapse' : 'separate')};
  border-spacing: 0 6px;
  width: 100%;

  .grid-container {
    display: table-row;
    gap: 16px;
    align-items: center;
    padding: 10px;
  }

  .stateColumn {
    text-align: center;
    min-width: 110px;
  }

  .alignCenter {
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .alignStart {
    text-align: start;
  }

  .alignEnd {
    text-align: end;
  }

  .alignRight {
    text-align: right;
  }
  .paddingLeft16 {
    padding-left: 16px;
    > span > div > div > .inputEnabled {
      min-width: 100px;
    }
  }
  .marginRight25 {
    padding-right: 10px;
  }
  .line {
    display: block;
    height: 1px;
    width: 100%;
    background: black;
  }
  .spani {
    display: flex;
    margin: auto;
    padding: 12px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .spaniRight {
    display: flex;
    text-align: end;
    align-items: end;
    justify-content: end;
  }

  .spaniLeft {
    display: flex;
    margin: auto;
    text-align: left;
    align-items: center;
    justify-content: left;
    padding-right: 10px;
  }

  .alignCenterHeaderState {
    justify-content: center;
    min-width: 110px;
    padding-left: 5px;
  }

  .alignRightHeader {
    padding-right: 10px;
  }

  .flexColumn {
    display: grid;
  }
  .maxWidth {
    max-width: 165px;
  }
  .maxWidthAlias {
    max-width: 200px;
  }

  .shortenedValue {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 5px;
  }

  .shortValue {
    display: flex;
    justify-content: center;
    min-width: 0;
  }

  thead {
    font-family: ${fonts.body};
    font-size: ${sizeFont.fontSize14};
    tr {
      background: ${colors.rowHeaderBackgroundTable};
      height: 48px;
      > th:first-of-type {
        padding: 0 10px;
      }
      > th {
        padding: 0 5px;
      }
    }
  }

  tbody {
    tr {
      margin-bottom: 6px;
      border-bottom: 1px solid #e3e3e4;
      text-align: center;
      height: 52px;
      :hover {
        background-color: ${colors.gray6};
      }
    }
    td {
      font-family: ${fonts.body};
      font-size: ${sizeFont.fontSize14};
      color: ${colors.textgray};
      min-width: 50px;
      padding: 0 5px;
    }
    td:has(.error-message) {
      padding-bottom: 12px;
    }
    tr:has(.rowEnabled) {
      background: ${colors.rowBackgroundTable};
    }
    tr:has(.rowDisabled) {
      input {
        pointer-events: none;
        background: ${colors.gray};
        border: 1px solid ${colors.backGray};
        border-radius: 4px;
        letter-spacing: 0.25px;
        color: ${colors.backGray};
      }
    }
    tr:has(input[type='checkbox']:disabled) {
      background: ${colors.white};
      font-family: ${fonts.body};
      font-size: ${sizeFont.fontSize14};
      input {
        pointer-events: none;
        border: 1px solid ${colors.backGray};
        border-radius: 4px;
        letter-spacing: 0.25px;
        color: ${colors.disabledTextColor};
        background-color: rgba(24, 25, 27, 0.12);
      }

      .billCode {
        color: ${colors.disabledTextColor};
      }

      .dueDate {
        color: ${colors.disabledTextColor};
      }

      .total {
        color: ${colors.disabledTextColor};
      }

      .detail {
        color: ${colors.disabledTextColor};
        cursor: default;
      }
    }

    .divider {
      border-top: 1px solid ${colors.borderGray};
      border-radius: 0px !important;
    }

    .disableHover {
      pointer-events: none;
    }

    td:first-of-type,
    td:last-of-type {
      padding: 0px 10px;
    }

    .emptyContent:hover {
      background-color: ${colors.white};
    }
  }

  .flexIcon {
    margin: 0;
    padding: 0px 0px 0px 5px;
    background-color: transparent;
    align-content: center;
    font-size: ${sizeFont.fontSize15};
    height: 10px;
    &:hover {
      filter: invert(0.5);
    }
    display: flex;
    align-items: center;
  }
`;

export default Table;
