/* eslint-disable import/no-unresolved */
import { Box, Button, Drawer, DrawerBody, DrawerContent, Icon, Tooltip, useDisclosure } from '@chakra-ui/react';
import { pushAnalyticsEvent } from '@wow/utils';
import { useContext, useEffect, useRef, useState } from 'react';
import { ShoppingCart } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import { DrawerBottomNavProps } from '../../interfaces/IDrawerProps';
import { createCartItem, createCartItemList } from '../../services/api';
import { BottomNavTexts } from '../../text/GeneralText';
import { transformNumberToCurrency } from '../../utils/converters';
import PreparePaymentButton from '../PreparePaymentButton';
import { Buttons, Container } from './styled';

function BottomNav({
  drawerVariant,
  rowsSelected,
  show,
  unselectBills,
  totalAmountPay,
  isErrorAmountPay,
  isWizardActive,
  amountToPayValues = {},
  setErrorOnAddItemToCart,
  setIsFinishPrepareAction,
}: DrawerBottomNavProps) {
  const {
    cartItemsCount,
    cartTotalAmount,
    getCartData,
    validateOperatibility,
    operative,
    cartFull,
    maxCartItem,
    setPaymentCheckoutProcess,
  } = useContext(AppContext);
  const [isFetching, setIsFetching] = useState(true);
  const [totalPaymentSelect, setTotalPaymentSelect] = useState(0);
  const [isButtonAddToCartDisabled, setIsButtonAddToCartDisabled] = useState(false);
  const [isCartFullWithBillsChecked, setIsCartFullWithBillsChecked] = useState(false);
  const { onClose } = useDisclosure();
  const navigate = useNavigate();
  const buttonAddCartBottomNavRef = useRef(null);
  useEffect(() => {
    const fetchCartData = async () => {
      try {
        await getCartData();
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsFetching(false);
      }
    };
    fetchCartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let amountTotal = totalAmountPay;
    if (cartTotalAmount) {
      amountTotal += cartTotalAmount;
    }
    setIsCartFullWithBillsChecked(cartItemsCount + rowsSelected.size > maxCartItem);
    setTotalPaymentSelect(amountTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsSelected, totalAmountPay]);

  useEffect(() => {
    setIsButtonAddToCartDisabled(cartFull || !operative || isCartFullWithBillsChecked);
  }, [cartFull, operative, isCartFullWithBillsChecked]);

  const handleAddToCart = async () => {
    buttonAddCartBottomNavRef.current.disabled = true;
    pushAnalyticsEvent({
      event: 'add_to_cart',
      content_group: 'Pagos',
    });
    try {
      const operativeTerminal = await validateOperatibility();
      if (operativeTerminal) {
        const addToCartRequests = [];

        rowsSelected.forEach(row => {
          if (row.enabled) {
            const modifiedAmount = amountToPayValues[row.hash] || row.amountToPay;
            addToCartRequests.push(createCartItem(row.hash, { amount: modifiedAmount }));
          }
        });
        await createCartItemList(addToCartRequests).then(result => {
          let errorOnAddToCartRequests = false;
          result.forEach(res => {
            if (res.status === 'rejected') {
              errorOnAddToCartRequests = true;
            }
          });
          if (!errorOnAddToCartRequests) {
            setPaymentCheckoutProcess(false);
            navigate(`/${routesNames.PaymentCart}`, { state: 'factura' });
          } else {
            setErrorOnAddItemToCart(true);
          }
        });
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      buttonAddCartBottomNavRef.current.disabled = null;
      buttonAddCartBottomNavRef.current = null;
    }
  };

  useEffect(() => {
    setIsButtonAddToCartDisabled(cartFull || !operative || isCartFullWithBillsChecked);
  }, [cartFull, operative, isCartFullWithBillsChecked]);

  const handleUnselect = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones seccion pagos',
      item_id: 'Deseleccionar',
    });
    unselectBills(true);
  };

  const handlePreparePayment = () => {
    pushAnalyticsEvent({
      event: 'preparar_pago',
      content_group: 'Pagos',
    });
    const billsToCart = [];
    rowsSelected.forEach(row => {
      if (row.enabled) {
        const modifiedAmount = amountToPayValues[row.hash] || row.amountToPay;
        billsToCart.push({ hash: row.hash, amount: modifiedAmount });
      }
    });
    return billsToCart;
  };

  const buttonAddCartBottomNav = (condition: boolean) => {
    return (
      <Button
        className='margin24'
        variant='primary-outline'
        size='md'
        leftIcon={<Icon as={ShoppingCart} />}
        onClick={handleAddToCart}
        isDisabled={condition}
        ref={buttonAddCartBottomNavRef}
      >
        {BottomNavTexts.addToCart}
      </Button>
    );
  };

  const buttonPreparePayBottomNav = (condition: boolean) => {
    return (
      <PreparePaymentButton
        buttonText={BottomNavTexts.preparePayment}
        fromButtonNav
        handleOnClickFromBottomNav={handlePreparePayment}
        isDisabled={isWizardActive ? !isWizardActive : condition}
        setErrorOnAddItemToCart={setErrorOnAddItemToCart}
        setIsFinishPrepareAction={setIsFinishPrepareAction}
        isCartFullWithBillsChecked={isCartFullWithBillsChecked}
        isErrorAmountPay={isErrorAmountPay}
      />
    );
  };

  return (
    <Drawer
      id='drawerBottom'
      isOpen={isWizardActive || (show && !isFetching && operative)}
      placement='bottom'
      onClose={onClose}
      size='lg'
      closeOnOverlayClick={false}
      variant={drawerVariant}
      blockScrollOnMount={false}
      trapFocus={false}
    >
      <Container>
        <DrawerContent className={`drawerContent ${isWizardActive && 'drawerDisabled'}`}>
          <DrawerBody className='drawerBody'>
            <Box className='content'>
              <Box className='totalBoldText'>
                <p>{BottomNavTexts.totalToPay(transformNumberToCurrency(totalPaymentSelect))}</p>
              </Box>
              <Box className='selectedBillsText'>
                <p>{BottomNavTexts.billsSelected(rowsSelected.size)}</p>
              </Box>
              {cartItemsCount > 0 && (
                <Box className='selectedBillsText'>
                  <p>{BottomNavTexts.billsOnCart(cartItemsCount)}</p>
                </Box>
              )}
            </Box>
            <Buttons>
              <Button className='margin24' size='md' variant='primary-text' onClick={handleUnselect}>
                {BottomNavTexts.deselectBills}
              </Button>
              {isCartFullWithBillsChecked && (
                <>
                  <Tooltip isDisabled={!isCartFullWithBillsChecked} hasArrow placement='top' label={BottomNavTexts.disabledButton}>
                    {buttonAddCartBottomNav(isButtonAddToCartDisabled)}
                  </Tooltip>
                  {buttonPreparePayBottomNav(isCartFullWithBillsChecked)}
                </>
              )}
              {!isCartFullWithBillsChecked && (
                <>
                  <Tooltip isDisabled={!isErrorAmountPay} hasArrow placement='top' label={BottomNavTexts.disabledWithErrorAmount}>
                    {buttonAddCartBottomNav(isErrorAmountPay)}
                  </Tooltip>
                  {buttonPreparePayBottomNav(isErrorAmountPay)}
                </>
              )}
            </Buttons>
          </DrawerBody>
        </DrawerContent>
      </Container>
    </Drawer>
  );
}

export default BottomNav;
