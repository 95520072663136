import { Tooltip } from '@chakra-ui/react';

import { IBill } from '../../../interfaces/ICarts';
import { ColumnsConfigTable } from '../../../interfaces/ITableComponent';
import { ColumnsConfigText, TooltipTexts } from '../../../text/GeneralText';
import { transformStringToCurrency, transformStringToDate } from '../../../utils/converters';
import BadgeState from '../../BillStatus';
import TextEllipsisWithTooltip from '../../TextEllipsisWithTooltip';
import DeleteBill from './DeleteBill';

export default (snackbarAction): ColumnsConfigTable<IBill>[] => [
  {
    accessorKey: 'status',
    id: 'Estado',
    header: 'Estado',
    cell: info => (
      <span>
        <BadgeState status={info.getValue() as string} />
      </span>
    ),
    align: 'start',
    classNameCell: 'alignStart',
  },
  {
    accessorKey: 'aliasName',
    id: 'NombreAsignado',
    header: 'Nombre asignado',
    cell: info => (
      <TextEllipsisWithTooltip text={(info.getValue() as string) || '-'} placement='top' textStyle='body.sm' padding='0px' maxWidth={315} />
    ),
    align: 'start',
    classNameCell: 'alignStart',
  },
  {
    accessorKey: 'bill',
    id: 'Código de factura',
    header: () => (
      <Tooltip placement='top' label={TooltipTexts.codigoDeFactura} hasArrow>
        <span>Código de factura</span>
      </Tooltip>
    ),
    cell: info => <TextEllipsisWithTooltip text={info.getValue() as string} placement='top' />,
    align: 'start',
    classNameCell: 'alignStart',
  },
  {
    accessorKey: 'expiration',
    id: 'Vencimiento',
    header: 'Vencimiento',
    cell: info =>
      info && <p>{info.getValue() !== 's/vto' ? transformStringToDate(info.getValue() as string) : ColumnsConfigText.sinVencimiento}</p>,
    align: 'start',
    classNameCell: 'alignStart',
  },
  {
    accessorFn: row => row.amount?.toString(),
    id: 'Importe a pagar',
    header: 'Importe a pagar',
    cell: info => (
      <span>
        <b>{transformStringToCurrency(info.getValue() as string)}</b>
      </span>
    ),
  },
  {
    accessorFn: row => row,
    id: 'Eliminar-snackbar',
    header: 'Eliminar',
    cell: info => <DeleteBill row={info.getValue() as any} snackbarAction={snackbarAction} />,
  },
];
