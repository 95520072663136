/* eslint-disable no-empty */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { Button, Grid, GridItem, useMediaQuery } from '@chakra-ui/react';
import { ColumnDef, RowData } from '@tanstack/react-table';
import { AlertComponent, AlertDescription, AlertTitle } from '@wow/alert';
import { OpenMode, pushAnalyticsEvent } from '@wow/utils';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import SkeletonOnLoading from '../../commons/SkeletonOnLoading';
import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import useChakraToast from '../../hooks/useChakraToast';
import { Account, TableCheckout } from '../../interfaces/ITableCheckout';
import { AccountSelectionFilterTexts, AlertBannerInformation, CheckoutTexts, ToastTexts } from '../../text/GeneralText';
import { renameKeys } from '../../utils/converters';
import CommonPage from '../CommonPage';
import EmptyStateScreen from '../EmptyStateScreen';
import WithFilter from '../filter/WithFilter';
import TableComponent from '../TableComponent';
import CartSummary from './component/CartSummary';
import ColumnsConfig from './component/ColumnsConfig';
import { TableContainer } from './component/styled';

function CheckoutList(): JSX.Element {
  const {
    validateOperatibility,
    operative,
    endOperative,
    startOperative,
    setRowsSelected,
    cartItemsCount,
    getAccountsData,
    getCartData,
    permissions,
    filterValues,
    handleFilters,
    clearFilterValues,
  } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState({ filter: false, dataTable: true });
  const [hasPermissionPreconfeccion, setHasPermissionPreconfeccion] = useState<boolean>(true);
  const [hasErrorConnection, setHasErrorConnection] = useState<boolean>(false);
  const [dataTable, setDataTable] = useState([]);
  const accountsDataTableRef = useRef([]);
  const navigate = useNavigate();
  const [isLessThan1000] = useMediaQuery('(max-width: 1000px)');
  const [isBalanceServiceUnavailable, setIsBalanceServiceUnavailable] = useState(false);
  const { ToastUtilities } = useChakraToast();
  const [deleteAllFilters, setDeleteAllFilters] = useState(false);
  const MIN_LENGTH = 0;
  const isAvailableDeleteAllFilters = filterValues && Object.values(filterValues).flat().length > MIN_LENGTH;
  const callToast = useCallback(
    (callback: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) => {
      return ToastUtilities.error({
        description: ToastTexts.errorMessage3,
        id: 'toast-id',
        toastOptions: { ctaLabel: ToastTexts.ctaLabel1, ctaOnClick: callback },
      });
    },
    [ToastUtilities],
  );

  const filterData = useCallback(async (data: string[]) => {
    try {
      ToastUtilities.closeAll();
      if (deleteAllFilters) setDeleteAllFilters(false);
      setIsLoading(prevState => ({ ...prevState, filter: true }));
      await validateOperatibility();
      const params = data?.length ? { bankcode: data.join() } : null;
      const response = await getAccountsData({ params });
      setDataTable(response.accounts);
    } catch (error) {
      callToast(() => {
        filterData(data).then();
      });
      throw new Error(error);
    } finally {
      setIsLoading(prevState => ({ ...prevState, filter: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteFilters = useCallback(async () => {
    try {
      await filterData([]);
      setDeleteAllFilters(true);
      clearFilterValues();
    } catch {}
  }, [filterData, clearFilterValues]);
  useEffect(() => {
    if (!isLoading.filter) {
      pushAnalyticsEvent({
        event: 'trackContentGroup',
        content_group: 'Pagos - Selección de cuenta',
      });
    }
  }, [isLoading.filter]);

  useEffect(() => {
    if (deleteAllFilters) {
      setDeleteAllFilters(false);
    }
  }, [deleteAllFilters]);

  useEffect(() => {
    const getData = async () => {
      try {
        const hasPermission = permissions?.generacionPreconfeccionPropia && permissions?.generacionConfeccion;
        setHasPermissionPreconfeccion(hasPermission);
        await validateOperatibility();
        if (hasPermission) {
          await getAccountsToCheckout();
          await getCartData();
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsLoading(prevState => ({ ...prevState, dataTable: false }));
      }
    };
    if (permissions) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  const getAccountsToCheckout = async () => {
    try {
      const response = (await getAccountsData({ params: null })) as TableCheckout;
      setDataTable(response.accounts);
      setIsBalanceServiceUnavailable(response.isBalanceServiceUnavalaible || false);

      accountsDataTableRef.current = Array.from(new Set(response?.accounts?.map(item => item.bankcode))).map(bankcode =>
        response?.accounts?.find(item => item.bankcode === bankcode),
      );
      accountsDataTableRef.current = accountsDataTableRef.current.map(obj => renameKeys(obj, { bankcode: 'code', bankName: 'codeName' }));
    } catch (error) {
      setHasErrorConnection(true);
    }
  };

  const goToAdmAccount = () => {
    window.open(
      OpenMode({
        module: 'urlComplete',
        urlComplete: `${process.env.URL_DOMAIN_SIB}${process.env.SIB_ACCOUNT}`,
      }),
    );
  };

  const retryAction = async () => {
    try {
      setHasErrorConnection(false);
      setIsLoading(prevState => ({ ...prevState, dataTable: true }));
      await getAccountsToCheckout();
      await getCartData();
    } catch (error) {
      setHasErrorConnection(true);
    } finally {
      setIsLoading(prevState => ({ ...prevState, dataTable: false }));
    }
  };

  const getScreen = () => {
    const commonEmptyStateProps = {
      page: 'CHECKOUT',
      hasButton: true,
      onRetry: () => {
        navigate(`/${routesNames.Home}`);
      },
    };
    if (isLoading.dataTable) {
      return <SkeletonOnLoading />;
    }
    if (!hasPermissionPreconfeccion) {
      return (
        <>
          <GridItem colSpan={12} marginBottom='24px'>
            {alertComponent}
          </GridItem>
          <div className='emptyState'>
            <EmptyStateScreen {...commonEmptyStateProps} variant='NO_ACCESS' />
          </div>
        </>
      );
    }
    if (dataTable.length === 0 && cartItemsCount >= 1 && !hasErrorConnection) {
      return (
        <>
          <CommonPage subheader={CheckoutTexts.mainSubHeader} text={CheckoutTexts.mainText} />
          <GridItem colSpan={12} marginBottom='24px'>
            {alertComponent}
          </GridItem>
          <div className='emptyState'>
            <EmptyStateScreen {...commonEmptyStateProps} variant='NO_DATA' onRetry={goToAdmAccount} />
          </div>
        </>
      );
    }
    if (cartItemsCount === 0) {
      return (
        <>
          <GridItem colSpan={12} marginBottom='24px'>
            {alertComponent}
          </GridItem>
          <div className='emptyState'>
            <EmptyStateScreen {...commonEmptyStateProps} variant='ACCESS_NO_DATA' />
          </div>
        </>
      );
    }
    if (hasErrorConnection) {
      return (
        <>
          <CommonPage subheader={CheckoutTexts.mainSubHeader} text={CheckoutTexts.mainText} />
          <GridItem colSpan={12} marginBottom='24px'>
            {alertComponent}
          </GridItem>
          <div className='emptyState'>
            <EmptyStateScreen {...commonEmptyStateProps} variant='ERROR_CONN' onRetry={retryAction} />
          </div>
        </>
      );
    }

    const columnsConfig = ColumnsConfig(isBalanceServiceUnavailable);
    return (
      <>
        <CommonPage subheader={CheckoutTexts.mainSubHeader} text={CheckoutTexts.mainText} />
        <GridItem colSpan={12} marginBottom='24px'>
          {alertComponent}
        </GridItem>
        <Grid h='auto' templateColumns='repeat(3, 1fr)' gap={4}>
          <GridItem colStart={1} colEnd={3}>
            <Grid
              borderWidth='1px'
              borderColor='rgb(190, 198, 220)'
              outline='none'
              padding='12px'
              minHeight='64px'
              borderRadius='8px'
              h='auto'
              templateColumns='repeat(6, 1fr)'
              gap={2}
            >
              <WithFilter
                data={accountsDataTableRef.current}
                id='filter'
                filterLabel={AccountSelectionFilterTexts.label}
                handleSendInfo={data => {
                  handleFilters('filter', data, values => {
                    filterData(values as string[]).then();
                  });
                }}
                titleMenu={AccountSelectionFilterTexts.title}
                resetInitialState={deleteAllFilters}
                filterTitle={AccountSelectionFilterTexts.actionFilter}
              />

              <GridItem colStart={8} colEnd={9}>
                <Button isDisabled={!isAvailableDeleteAllFilters} onClick={deleteFilters} variant='primary-text'>
                  {AccountSelectionFilterTexts.generalDeleteAction}
                </Button>
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem colStart={3} colEnd={5} rowStart={1}>
            <CartSummary />
          </GridItem>
          <GridItem colStart={1} colEnd={3} rowStart={3} minH='32vh'>
            <TableContainer $isLessThan1000={isLessThan1000}>
              {isLoading.filter ? (
                <SkeletonOnLoading />
              ) : (
                <TableComponent<Account>
                  columnsData={columnsConfig as ColumnDef<RowData>[]}
                  inputData={dataTable}
                  setSelectedRows={setRowsSelected}
                  isMultiRowSelectionEnabled={false}
                  isHeaderHidden
                />
              )}
            </TableContainer>
          </GridItem>
        </Grid>
      </>
    );
  };

  const alertComponent = !operative ? (
    <AlertComponent className='alertConfig' id='alert-cartOperative' variant='destructive' width='100%'>
      <AlertTitle>{AlertBannerInformation.error.operatibilityTitle}</AlertTitle>
      <AlertDescription>{AlertBannerInformation.error.operatibilityDescription(startOperative, endOperative)}</AlertDescription>
    </AlertComponent>
  ) : null;

  return <>{getScreen()}</>;
}

export default CheckoutList;
