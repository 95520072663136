import { useDisclosure } from '@chakra-ui/react';
import { useContext } from 'react';

import Cancel from '../../../../assets/images/Cancel';
import TrashIcon from '../../../../assets/images/deleteIcon.svg';
import AppContext from '../../../../context/Provider';
import { IBill } from '../../../../interfaces/ICarts';
import { deleteCartBill } from '../../../../services/api';
import { AliasDeleteModalText, ConfirmationModalTexts } from '../../../../text/GeneralText';
import { styledModalContentDeleteCompany } from '../../../AccordionCart/styled';
import ConfirmationModal from '../../../ConfirmationModal';
import { PaddingModal, SubTitle, Title } from '../../../ConfirmationModal/styled';

interface IDeleteBill {
  row: IBill;
  snackbarAction: (company: string, success: boolean, type: string) => void;
}
function DeleteBill({ row, snackbarAction }: IDeleteBill) {
  const { getCartData, setCompanyName } = useContext(AppContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDeleteBill = async () => {
    try {
      await deleteCartBill(row.hash).then(() => {
        getCartData();
        setCompanyName(row.company);
        snackbarAction(row.aliasName ? `${row.company} - ${row.aliasName}` : row.company, true, 'bill');
      });
    } catch {
      snackbarAction(row.aliasName ? `${row.company} - ${row.aliasName}` : row.company, false, 'bill');
    }
  };

  const handleOpenModal = () => {
    onOpen();
  };

  const renderModal = () => {
    return (
      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        ModalContentCss={styledModalContentDeleteCompany}
        fn={async () => {
          await handleDeleteBill();
        }}
        cancelText={AliasDeleteModalText.cancelButton}
      >
        <PaddingModal>
          <div className='img'>
            <Cancel />
          </div>
          <Title>{ConfirmationModalTexts.delete.bill.title}</Title>
          <SubTitle>{ConfirmationModalTexts.delete.bill.subtitle3}</SubTitle>
        </PaddingModal>
      </ConfirmationModal>
    );
  };

  return (
    <button onClick={handleOpenModal} data-testid='delete-bill-btn'>
      <img src={TrashIcon} alt='trashIcon' width='24px' height='auto' />
      {renderModal()}
    </button>
  );
}

export default DeleteBill;
