/* eslint-disable react/no-array-index-key */
import { Grid, GridItem, Skeleton } from '@chakra-ui/react';
import { TablePaginationComponent } from '@wow/table-pagination';
import { pushAnalyticsEvent } from '@wow/utils';
import { useEffect, useState } from 'react';

import useChakraToast from '../../hooks/useChakraToast';
import { Pagination as PaginationInterfaces } from '../../interfaces/IAliasFavorites';
import { deleteAlias, getAliasFavorites, updateServiceAlias } from '../../services/api';
import { SnackbarTexts, ToastTexts } from '../../text/GeneralText';
import AliasModal from '../AliasModal';
import EmptyStateScreen from '../EmptyStateScreen';
import FavoriteItem from './FavoriteItem';
import { Container } from './styled';

function FavoriteHome() {
  const { ToastUtilities } = useChakraToast();
  const [aliasFavoriteList, setAliasFavoriteList] = useState([]);
  const [selectedPaymentAlias, setSelectedPaymentAlias] = useState(null);
  const [, setShowSnackbar] = useState(false);
  const [aliasPayload, setAliasPayload] = useState(undefined);
  const [isLoading, setIsLoading] = useState<boolean | number>(false);
  const [, setAliasToDelete] = useState(null);
  const [pagination, setPagination] = useState<PaginationInterfaces>({ totalItems: 0, itemsByPage: 0, totalPages: 0, currentPage: 0 });
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(15);
  const [hasErrorConnection, setHasErrorConnection] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getAlias();
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Pagos  - Servicios favoritos',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size]);

  //! This is a patch for the table component component 15/11/2024 removed when DS have the fix for this
  useEffect(() => {
    const selectorCenterElement = document.querySelector('[data-testid="select"]');
    if (selectorCenterElement) {
      if (pagination.totalItems <= 15) {
        selectorCenterElement.setAttribute('disabled', '');
      } else {
        selectorCenterElement.removeAttribute('disabled');
      }
    }
  }, [page, pagination.totalItems]);
  const getAlias = () => {
    getAliasFavorites(page, size)
      .then(res => {
        setIsLoading(false);
        setAliasFavoriteList(res.data.aliasFavoriteEntityList);
        setPagination(res.data.pagination);
        setHasErrorConnection(false);
      })
      .catch(() => {
        setIsLoading(false);
        setHasErrorConnection(true);
      });
  };

  // handle Update Success
  const handleEditAliasSuccess = serviceAlias => {
    const description = SnackbarTexts?.editAliasSuccess(selectedPaymentAlias.companyName, serviceAlias);
    ToastUtilities.success({
      description,
      id: 'toast-id',
    });
    getAlias();
  };

  const handleEditAliasError = payload => {
    const description = SnackbarTexts.editAliasError(payload.companyName, payload.currentAlias);
    setAliasPayload(payload);
    ToastUtilities.error({
      description,
      id: 'toast-id',
      toastOptions: { ctaLabel: ToastTexts.ctaLabel1, ctaOnClick: () => retrySaveAlias() },
    });
  };

  const retrySaveAlias = () => {
    setShowSnackbar(false);
    setIsLoading(true);
    updateServiceAlias(aliasPayload.tempAlias, aliasPayload.aliasId)
      .then(() => {
        const message = SnackbarTexts.editAliasSuccess(aliasPayload.companyName, aliasPayload.tempAlias);
        setAliasPayload(undefined);
        handleEditAliasSuccess(message);
      })
      .catch(() => {
        handleEditAliasError(aliasPayload);
      })
      .finally(() => setIsLoading(false));
  };

  const handleDeleteAlias = alias => {
    let description = '';
    setIsLoading(true);
    deleteAlias(alias.aliasId)
      .then(() => {
        // back to previous page when the current page hasnt items
        if (aliasFavoriteList.length === 1 && page > 0) {
          setPage(page - 1);
        } else {
          getAlias();
        }
        description = SnackbarTexts.deleteAliasSucess(alias.companyName, alias.alias);
        setAliasToDelete(null);
        ToastUtilities.success({
          description,
          id: 'toast-id',
        });
      })
      .catch(() => {
        description = SnackbarTexts.deleteAliasError(alias.companyName, alias.alias);
        setAliasToDelete(alias);
        ToastUtilities.error({
          description,
          id: 'toast-id',
          toastOptions: { ctaLabel: ToastTexts.ctaLabel1, ctaOnClick: () => retrySaveAlias() },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const cardMockSkeleton = () => {
    return <div className='gridSkeleton'>{skeletonLoop()}</div>;
  };

  const skeletonLoop = () => {
    const arr = Array.from({ length: 6 });
    return arr.map((_, index) => <Skeleton key={index} height='120px' borderRadius='12px' marginBottom='16px' />);
  };

  const viewCard = () => {
    if (hasErrorConnection) {
      return <EmptyStateScreen page='FAVORITES' variant='ERROR_CONN' hasButton onRetry={getAlias} />;
    }
    if (aliasFavoriteList.length === 0) {
      return <EmptyStateScreen page='FAVORITES' variant='NO_DATA' hasButton={false} />;
    }
    return (
      <Grid templateColumns='repeat(12, 1fr)' columnGap={3} rowGap={0} className='favoritesContainer'>
        {aliasFavoriteList.map(item => {
          return (
            <GridItem key={item} colSpan={4}>
              <FavoriteItem item={item} key={item.aliasId} onDelete={handleDeleteAlias} onEdit={() => setSelectedPaymentAlias(item)} />
            </GridItem>
          );
        })}
      </Grid>
    );
  };

  return (
    <Container id='favorites'>
      {isLoading ? cardMockSkeleton() : viewCard()}
      {!hasErrorConnection && (
        <TablePaginationComponent
          totalPages={pagination.totalPages || 1}
          currentIndex={pagination.currentPage}
          totalRecords={pagination.totalItems}
          perPage={[15, 30, 45]}
          disabledPerPage={pagination.totalItems <= 15}
          currentPerPage={pagination.itemsByPage}
          onNext={() => setPage(page + 1)}
          onPrev={() => setPage(page - 1)}
          onFirst={() => setPage(0)}
          onLast={() => setPage(pagination.totalPages - 1)}
          onJump={value => setPage(Number(value))}
          onPerPageChange={value => {
            setSize(Number(value));
            setPage(0);
          }}
        />
      )}
      {selectedPaymentAlias && (
        <AliasModal
          variant='edit'
          isOpen={selectedPaymentAlias !== null}
          setAliasIdLoading={setIsLoading}
          onClose={() => setSelectedPaymentAlias(null)}
          onSaveSuccess={handleEditAliasSuccess}
          onSaveError={handleEditAliasError}
          paymentAlias={selectedPaymentAlias}
        />
      )}
    </Container>
  );
}

export default FavoriteHome;
